import { Colors, Text, TouchableOpacity, View } from 'react-native-ui-lib';
import FeedIcon from '../../assets/res/feed.svg';
import ProfileIcon from '../../assets/res/profile.svg';
import TreeIcon from '../../assets/res/tree.svg';
import React, { useContext } from 'react';
import { LocalizedStringsContext } from '../localization/localization.native';
import LinearGradient from 'react-native-linear-gradient';
import { MaterialIcons } from '@expo/vector-icons';

export function NavigationBar({
  state,
  descriptors,
  navigation,
}: any): JSX.Element {
  const strings = useContext(LocalizedStringsContext);
  return (
    <View style={{ backgroundColor: 'transparent' }}>
      <LinearGradient
        colors={['transparent', Colors.secondaryBG]}
        style={{ marginTop: -60, paddingTop: 5 }}
        locations={[0, 0.25]}
      >
        <View style={{
          flexDirection: 'row',
          margin: 0,
          borderRadius: 0,
          backgroundColor: 'transparent',
        }}>
          {state.routes.map((route: any, index: number) => {
            const { options } = descriptors[route.key];
            let label =
              options.tabBarLabel !== undefined
                ? options.tabBarLabel
                : options.title !== undefined
                  ? options.title
                  : route.name;
            if (label === 'Feed') {
              label = strings.public;
            } else if (label === 'PersonalTree') {
              label = strings.private;
            } else if (label === 'Profile') {
              label = strings.profile;
            } else if (label === 'Groups') {
              label = strings.groups;
            } else if (label === 'register') {
              return null;
            } else if (label === 'login') {
              return null;
            }

            const isFocused = state.index === index;

            const onPress = () => {
              const event = navigation.emit({
                type: 'tabPress',
                target: route.key,
                canPreventDefault: true,
              });

              if (!isFocused && !event.defaultPrevented) {
                navigation.navigate({ name: route.name, merge: true });
              }
            };

            const onLongPress = () => {
              navigation.emit({
                type: 'tabLongPress',
                target: route.key,
              });
            };

            const iconSize = 24;
            const fill = isFocused ? Colors.activeText : Colors.inactiveText;
            const icon =
              label === strings.public ? (
                <FeedIcon width={iconSize} height={iconSize} fill={fill} />
              ) : label === strings.private ? (
                <TreeIcon width={iconSize} height={iconSize} fill={fill} />
              ) : label === strings.profile ? (
                <ProfileIcon width={iconSize} height={iconSize} fill={fill} />
              ) : (
                <MaterialIcons name="groups" size={iconSize} color={fill} />
              );

            const text = isFocused ? (
              <Text bold style={{ color: fill }}>
                {label}
              </Text>
            ) : (
              <Text style={{ color: fill }}>{label}</Text>
            );

            return (
              <TouchableOpacity
                flex
                center
                padding-15
                onPress={onPress}
                onLongPress={onLongPress}
                key={index}>
                {icon}
                {text}
              </TouchableOpacity>
            );
          })}
        </View>
      </LinearGradient>
    </View>
  );
}
