import { useEffect, useState } from 'react';
import { Batch, Timestamp, TimestampNow, updateData as _updateData, arrayRemove, getContainerDataFromDB, getUserId, onContainerDataSnapshot, saveContainerToDB } from './firebaseWrapper/firebaseWrapper';
import { dbPath, ElementPath, userId, elementId, PathConstants, ContainerType, StanceType, VoteType, FirestoreConstants, PublicPath, PersonalPath, GroupPath } from './typings/types';
import {
  convertInfoToPath,
  convertPathToInfo,
  convertToPublicElementInfo,
  getIdFromPath,
  getLocationFromPath,
  isEmpty,
  pathIsPublic,
} from './helperFunctions';
import make_uuid from 'react-uuid';

export interface _ContainerData {
  type: ContainerType;
  // TODO: Could be removed by using the documents key as id, which is essentially the same.
  path: dbPath;

  // Not necessarily needed for personal elements.
  userId: userId;
  parentPaths: dbPath[];
  childPaths: { [key: dbPath]: StanceType }[];
  votes: { [key: userId]: VoteType };
  creationTime: Timestamp;
  groupId?: string;
  topics?: string[];
}

// not sure if the overriding of the data variable makes updates impossible when using functions of the super class.
// If polymorphism is not applied, it is possible that only the variable of the super class is updated and updates are not
// reflected in getters. However, this is probably not relevant if setters directly interact with firestore since this will
// cause a new contructor invocation completly ommiting the old state and build the new state from the ground up.
export class ContainerData {
  protected readonly data: _ContainerData;
  protected groupId?: string;

  // Used to directly retrieve parent specific properties.
  // Also used in the creation process to determine where to add the container in the graph.
  // This is only a temporary local variable and is not stored in the database.
  protected postponeUpdate = false;

  public constructor(data: _ContainerData, groupId?: string) {
    this.data = data;
    this.groupId = groupId;
  }

  public static makeInDB(data: _ContainerData, stance: StanceType, groupId?: string) {
    saveContainerToDB(new ContainerData(data, groupId), stance, groupId);
  }

  public static makeFromListener(data: _ContainerData, groupId?: string): ContainerData {
    return new ContainerData(data, groupId);
  }

  public static async getFromDB(info: ElementPath): Promise<ContainerData> {
    return getContainerDataFromDB<ContainerData>(info);
  }

  public getType(): ContainerType {
    return this.data.type;
  }

  public getTypeAsLocalizedString(localizedStrings: any): string {
    let result = localizedStrings.none;
    Object.entries(ContainerType).forEach(([key, value]) => {
      if (value === this.data.type) {
        result = localizedStrings[key.toLowerCase()];
      }
    });
    return result;
  }

  public getData(): _ContainerData {
    return this.data;
  }

  public getPath(): dbPath {
    return this.data.path || FirestoreConstants.NO_PATH;
  }

  public getLocation(): PathConstants {
    return this.getGroupId() ? PathConstants.GROUP : getLocationFromPath(this.getPath());
  }

  public getId(): elementId {
    return getIdFromPath(this.getPath());
  }

  public getGroupId(): string | undefined {
    return this.groupId;
  }

  public getInfo(): ElementPath {
    if (this.getLocation() === PathConstants.PUBLIC) {
      return {
        loc: this.getLocation(),
        id: this.getId(),
        type: this.getType(),
      } as PublicPath;
    } else if (this.getLocation() === PathConstants.PERSONAL) {
      return {
        loc: this.getLocation(),
        id: this.getId(),
        type: this.getType(),
        userId: this.getUserId(),
      } as PersonalPath;
    } else if (this.getLocation() === PathConstants.GROUP) {
      return {
        loc: this.getLocation(),
        id: this.getId(),
        type: this.getType(),
        groupId: this.getGroupId(),
      } as GroupPath;
    } else {
      throw new Error('Unknown location.');
    }
  }

  public isPublic(): boolean {
    return pathIsPublic(this.getPath());
  }

  public isPersonal(): boolean {
    return !this.isPublic() && !this.isInGroup();
  }

  public isInGroup(): boolean {
    return this.getGroupId() !== undefined;
  }

  public isOwnedByCurrentUser(): boolean {
    return this.getUserId() === getUserId();
  }

  public getUserId(): userId {
    return this.data.userId;
  }

  public setPath(path: dbPath): void {
    this.data.path = path;
    this.applyUpdate();
  }

  public getChildData(): { [key: dbPath]: StanceType }[] {
    return this.data.childPaths;
  }

  public setChildData(childData: { [key: dbPath]: StanceType }[]): void {
    this.data.childPaths = childData;
    this.applyUpdate();
  }

  public getChildPaths(): dbPath[] {
    return this.data.childPaths.map(entry => Object.keys(entry)[0]);
  }

  public getChildInfos(): ElementPath[] {
    return this.data.childPaths.map(entry => {
      return { ...this.getInfo(), ...convertPathToInfo(Object.keys(entry)[0]) }
    });
  }

  public setChildPaths(childPaths: { [key: elementId]: StanceType }[]): ContainerData {
    this.data.childPaths = childPaths;
    this.applyUpdate();
    return this;
  }

  public getChildStances(): StanceType[] {
    return this.data.childPaths.map(entry => Object.values(entry)[0]);
  }

  public getChildStance(childPath: dbPath): StanceType {
    const index = this.getChildPaths().indexOf(childPath);
    if (index === -1) {
      throw new Error('Child id not found.');
    }
    return this.getChildStances()[index];
  }

  public setChildStance(childInfo: ElementPath, stance: StanceType) {
    const childPath = convertInfoToPath(childInfo);
    const index = this.getChildPaths().indexOf(childPath);
    if (index === -1) {
      throw new Error('Child id not found.');
    }
    this.data.childPaths[index] = { [childPath]: stance };

    this.applyUpdate();
  }

  public getParentPaths(): dbPath[] {
    return this.data.parentPaths;
  }

  // TODO: Test if this works correctly for public, personal and group elements.
  public getParentInfos(): ElementPath[] {
    return this.data.parentPaths.map(path => {
      return { ...this.getInfo(), ...convertPathToInfo(path, this.getGroupId()) }
    });
  }

  public setParentPaths(parentPaths: dbPath[]): void {
    this.data.parentPaths = parentPaths;
    this.applyUpdate();
  }

  public getVotes(): { [key: userId]: VoteType } {
    return this.data.votes;
  }

  public getUpvotes(): number {
    return Object.values(this.getVotes()).filter(
      vote => vote === VoteType.UPVOTE,
    ).length;
  }

  public getDownvotes(): number {
    return Object.values(this.getVotes()).filter(
      vote => vote === VoteType.DOWNVOTE,
    ).length;
  }

  public getOwnVote(memberId?: string): VoteType {
    // TODO: Delete later because this is needed for legacy data.
    if (!this.data.votes) {
      this.data.votes = {};
      this.applyUpdate();
    }
    try {
      const userId = getUserId();
      if (this.data.votes[userId]) {
        return this.data.votes[userId];
      } else if (this.data.votes[memberId]) {
        return this.data.votes[memberId];
      }
    } catch (error) {
      console.error(error);
      return VoteType.NOVOTE;
    }
  }

  public setOwnVote(vote: VoteType, memberId?: string) {
    try {
      const userId = getUserId();
      if (vote !== VoteType.NOVOTE) {
        if (memberId) {
          this.data.votes[memberId] = vote;
        } else {
          this.data.votes[userId] = vote;
        }
      } else {
        if (memberId) {
          delete this.data.votes[memberId];
        } else {
          delete this.data.votes[userId];
        }
      }
      this.applyUpdate();
    } catch (error) {
      console.error(error);
    }
  }

  public getCreationTime(): Timestamp {
    return this.data.creationTime;
  }

  public setCreationTime(creationTime: Timestamp) {
    this.data.creationTime = creationTime;
    this.applyUpdate();
  }

  public setCreationTimeNow() {
    this.setCreationTime(TimestampNow());
  }

  public getTopics(): string[] {
    return this.data.topics || [];
  }

  public keepUpdatesLocal(): ContainerData {
    this.postponeUpdate = true;
    return this;
  }

  public pushLocalUpdates() {
    this.postponeUpdate = false;
    this.applyUpdate();
  }

  protected applyUpdate() {
    if (!this.postponeUpdate) {
      this.updateData(this.data);
    }
  }

  public convertToPublicData(): ContainerData {
    this.keepUpdatesLocal();
    this.setCreationTimeNow();
    this.setPath(convertInfoToPath(convertToPublicElementInfo(this.getInfo())));
    this.setChildPaths(
      this.data.childPaths.map(entry => {
        return {
          [convertInfoToPath(convertToPublicElementInfo(convertPathToInfo(Object.keys(entry)[0])))]:
            Object.values(entry)[0],
        };
      }),
    );
    const parentPaths = this.data.parentPaths
      .map(path => convertInfoToPath(convertToPublicElementInfo(convertPathToInfo(path))))
      .filter(path => path !== null) as dbPath[];
    this.data.parentPaths = parentPaths;
    return this;
  }

  /**
   * This function is used to minimize database update calls when multiple changes must be applied to the data.
   *
   * @param functionsToBeApplied Inside this function, multiple functions changing the data can be applied. The update will only be applied once after all functions have been executed.
   */
  // TODO: Many of these functions might benefit from being async. However, this would require to change the way the functions are called.
  public applyAll(functionsToBeApplied: (container: ContainerData) => void) {
    this.postponeUpdate = true;
    functionsToBeApplied(this);
    this.postponeUpdate = false;
    this.applyUpdate();
  }

  /**
   * !Careful when using this function as it does not check if the data is valid!
   * Overrides the data. Used internally by other methods.
   *
   * @param data Data to use.
   */
  public async updateData(data: _ContainerData): Promise<void> {
    return _updateData<ContainerData>(this.getInfo(), data);
  }

  public async removeFromParent(parentInfo: ElementPath) {
    // TODO: Use Batch delete. Aggregate all changes and then apply them at once.
    if (
      (this.getParentPaths().length === 1 &&
        this.getParentPaths()[0] === convertInfoToPath(parentInfo)) ||
      this.getParentPaths().length === 0
    ) {
      this.delete();
    } else {
      // Remove id from parent's childPaths
      await _updateData<ContainerData>(parentInfo, {
        childPaths: arrayRemove(this.getPath()),
      })
        .catch(error => {
          console.error("Error updating active parent's childPaths: ", error);
        });

      // Remove parent id from this container's parentPaths.
      await _updateData<ContainerData>(this.getInfo(), {
        parentPaths: arrayRemove(convertInfoToPath(parentInfo)),
      })
        .catch(error => {
          console.error('Error updating containers parentPaths: ', error);
        });
    }
  }

  /* TODO: Implement this function.
    // Think about implementation. Can I use the listener here? Or do I need to use the db directly? Can I await the container?
    // Does this block the main thread? If so, how can I avoid this?
    /**
     * Use with caution. This function deletes the container entirely from the db.
     * If child container are only used by this container, they will be deleted as well.
     */
  public async delete() {
    setTimeout(async () => {
      const batch = new Batch();

      if (this.getParentPaths().length > 0 && this.getParentInfos()[0].type !== ContainerType.GROUP) {
        try {
          const parentContainer = await getContainerDataFromDB<ContainerData>(this.getParentInfos()[0]);
          parentContainer.keepUpdatesLocal();
          parentContainer.setChildPaths(
            parentContainer
              .getChildData()
              .filter(
                pathAndStance =>
                  Object.keys(pathAndStance)[0] !== this.getPath(),
              ),
          );
          batch.update(
            this.getParentInfos()[0],
            parentContainer.getData(),
          );
        } catch (error) {
          console.error(error);
        }
      }

      batch.delete(this.getInfo());

      await Promise.all(
        this.getChildInfos().map(async childInfo => {
          try {
            const childContainer = await getContainerDataFromDB<ContainerData>(childInfo);
            childContainer.keepUpdatesLocal();
            childContainer.setParentPaths(
              childContainer
                .getParentPaths()
                .filter(path => path !== this.getPath()),
            );
            batch.update(childInfo, childContainer.getData());
          } catch (error) {
            console.error(error);
          }
        }),
      );

      batch.commit();
    }, 0);
  }
}

function isGroupPath(info: any): info is GroupPath {
  if (info) {
    return 'groupId' in info;
  } else {
    throw new Error("Info is undefined.");
  }
}

export function useContainerListener(info: ElementPath): {
  container: ContainerData;
  loading: boolean;
  error: Error | null;
} {
  const [container, setContainerData] = useState(
    new ContainerData({
      type: ContainerType.NONE,
      parentPaths: [],
      childPaths: [],
      path: '',
      userId: '',
      votes: {},
      creationTime: null,
    },
      info && isGroupPath(info) ? info.groupId : undefined),
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {

    if (info === undefined) {
      setError(new Error('Empty element path.'));
      return;
    }

    //TODO: The error handling on all listeners in not really solved in a useful way yet.
    if (isEmpty(info.id) || isEmpty(info.loc)) {
      setError(new Error('Cannot listen to container with an empty id.'));
      return;
    }

    const unsubscribe = onContainerDataSnapshot<_ContainerData>(info, data => {
      const containerIsRemoved = data === undefined;
      if (!containerIsRemoved) {
        const typeId = data.type;
        // Seems unnecessary to check for the type here. However, if it is not checked, methods of the sub classes cannot be used.
        switch (typeId) {
          case ContainerType.STATEMENT:
            setContainerData(StatementData.makeFromListener(
              data as _StatementData,
              isGroupPath(info) ? info.groupId : undefined
            ));
            break;
          case ContainerType.SOURCE:
            setContainerData(SourceData.makeFromListener(
              data as _SourceData,
              isGroupPath(info) ? info.groupId : undefined
            ));
            break;
          case ContainerType.ARGUMENT:
            setContainerData(ArgumentData.makeFromListener(
              data as _ArgumentData,
              isGroupPath(info) ? info.groupId : undefined
            ));
            break;
          case ContainerType.CATEGORY:
            setContainerData(CategoryData.makeFromListener(
              data as _CategoryData,
              isGroupPath(info) ? info.groupId : undefined
            ));
            break;
          default:
            setContainerData(ContainerData.makeFromListener(
              data as _ContainerData,
              isGroupPath(info) ? info.groupId : undefined
            ));
            break;
        }
      }
      setLoading(false);
    }, error => {
      setError(error);
      setLoading(false);
    });


    return () => {
      unsubscribe();
    };
  }, []);

  return { container: container, loading: loading, error: error };
}

export interface _StatementData extends _ContainerData {
  content: string;
}

export class StatementData extends ContainerData {
  // TODO: Check if this can be removed and only the super class variable is used.
  protected readonly data: _StatementData;

  public constructor(data: _StatementData, groupId?: string) {
    super(data, groupId);
    this.data = data;
  }

  public makeInDB(data: _StatementData, stance: StanceType) {
    saveContainerToDB(new StatementData(data), stance);
  }

  public static makeFromListener(data: _StatementData, groupId?: string): StatementData {
    return new StatementData(data, groupId);
  }

  public getContent(): string {
    return this.data.content;
  }

  public setContent(content: string): StatementData {
    this.data.content = content;
    this.applyUpdate();
    return this;
  }

  public applyAll(functionsToBeApplied: (statement: StatementData) => void) {
    this.postponeUpdate = true;
    functionsToBeApplied(this);
    this.postponeUpdate = false;
    this.applyUpdate();
  }
}

export function useStatementListener(info: ElementPath): {
  statement: StatementData;
  loading: boolean;
  error: Error | null;
} {
  const [statement, setStatement] = useState(
    new StatementData({
      type: ContainerType.STATEMENT,
      parentPaths: [],
      childPaths: [],
      path: '',
      userId: '',
      content: '',
      votes: {},
      creationTime: null,
    }),
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    try {
      if (!loading && statement.getType() != ContainerType.STATEMENT) {
        throw new Error('ContainerData is not a Statement!');
      }

      if (isEmpty(info.id) || isEmpty(info.loc)) {
        throw new Error('Cannot listen to container without any id.');
      }

      const unsubscribe = onContainerDataSnapshot<_StatementData>(info, data => {
        setStatement(
          StatementData.makeFromListener(data, info && isGroupPath(info) ? info.groupId : undefined),
        );
        setLoading(false);
      }, error => {
        setError(error);
        setLoading(false);
      });

      return () => {
        unsubscribe();
      };
    } catch (error: any) {
      console.error(error);
      setError(error);
    }
  }, []);

  return { statement: statement, loading: loading, error: error };
}

export interface _SourceData extends _StatementData {
  url: string;
  reference: string;
}

export class SourceData extends StatementData {
  protected readonly data: _SourceData;

  public constructor(data: _SourceData, groupId?: string) {
    super(data, groupId);
    this.data = data;
  }

  public makeInDB(data: _SourceData, stance: StanceType) {
    saveContainerToDB(new SourceData(data), stance);
  }

  public static makeFromListener(data: _SourceData, groupId?): SourceData {
    return new SourceData(data, groupId);
  }

  public getUrl(): string {
    return this.data.url;
  }

  public setUrl(url: string): SourceData {
    this.data.url = url;
    this.applyUpdate();
    return this;
  }

  public getReference(): string {
    return this.data.reference;
  }

  public setReference(reference: string): SourceData {
    this.data.reference = reference;
    this.applyUpdate();
    return this;
  }

  public applyAll(functionsToBeApplied: (source: SourceData) => void) {
    this.postponeUpdate = true;
    functionsToBeApplied(this);
    this.postponeUpdate = false;
    this.applyUpdate();
  }
}

export function useSourceListener(info: ElementPath): {
  source: SourceData;
  loading: boolean;
  error: Error | null;
} {
  const [source, setSource] = useState(
    new SourceData({
      type: ContainerType.SOURCE,
      parentPaths: [],
      childPaths: [],
      path: '',
      userId: '',
      content: '',
      url: '',
      reference: '',
      votes: {},
      creationTime: null,
    }),
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    try {
      if (!loading && source.getType() != ContainerType.SOURCE) {
        throw new Error('ContainerData is not a Source!');
      }

      if (isEmpty(info.id) || isEmpty(info.loc)) {
        throw new Error('Cannot listen to container without any id.');
      }

      const unsubscribe = onContainerDataSnapshot<_SourceData>(info, data => {
        setSource(
          SourceData.makeFromListener(data, info && isGroupPath(info) ? info.groupId : undefined),
        );
        setLoading(false);
      }, error => {
        setError(error);
        setLoading(false);
      });

      return () => {
        unsubscribe();
      };
    } catch (error: any) {
      console.error(error);
      setError(error);
    }
  }, []);

  return { source: source, loading: loading, error: error };
}

export type _ArgumentData = _ContainerData;

export class ArgumentData extends ContainerData {
  protected readonly data: _ArgumentData;

  public constructor(data: _ArgumentData, groupId?: string) {
    super(data, groupId);
    this.data = data;
  }

  public makeInDB(data: _ArgumentData, stance: StanceType) {
    saveContainerToDB(new ArgumentData(data), stance);
  }

  public static makeFromListener(
    data: _ArgumentData,
    groupId?: string,
  ): ArgumentData {
    return new ArgumentData(data, groupId);
  }
}

export function useArgumentListener(info: ElementPath): {
  argument: ArgumentData;
  loading: boolean;
  error: Error | null;
} {
  const [argument, setStatementGroup] = useState(
    new ArgumentData({
      type: ContainerType.ARGUMENT,
      parentPaths: [],
      childPaths: [],
      path: '',
      userId: '',
      votes: {},
      creationTime: null,
    }),
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    try {
      if (
        !loading &&
        argument.getType() != ContainerType.ARGUMENT
      ) {
        throw new Error('ContainerData is not an Argument!');
      }

      if (isEmpty(info.id) || isEmpty(info.loc)) {
        throw new Error('Cannot listen to container without any id.');
      }

      const unsubscribe = onContainerDataSnapshot<_ArgumentData>(info, data => {
        setStatementGroup(ArgumentData.makeFromListener(data, info && isGroupPath(info) ? info.groupId : undefined));
        setLoading(false);
      }, error => {
        setError(error);
        setLoading(false);
      });

      return () => {
        unsubscribe();
      };
    } catch (error: any) {
      console.error(error);
      setError(error);
    }
  }, []);

  return { argument: argument, loading: loading, error: error };
}

export interface _CategoryData extends _ContainerData {
  name: string;
}

export class CategoryData extends ContainerData {
  protected readonly data: _CategoryData;

  public constructor(data: _CategoryData, groupId?: string) {
    super(data, groupId);
    this.data = data;
  }

  public makeInDB(data: _CategoryData, stance: StanceType) {
    saveContainerToDB(new CategoryData(data), stance);
  }

  public static makeFromListener(data: _CategoryData, groupId?: string): CategoryData {
    return new CategoryData(data, groupId);
  }

  public getName(): string {
    return this.data.name;
  }

  public setName(name: string): CategoryData {
    this.data.name = name;
    this.applyUpdate();
    return this;
  }

  public applyAll(functionsToBeApplied: (category: CategoryData) => void) {
    this.postponeUpdate = true;
    functionsToBeApplied(this);
    this.postponeUpdate = false;
    this.applyUpdate();
  }
}

export function useCategoryListener(info: ElementPath): {
  category: CategoryData;
  loading: boolean;
  error: Error | null;
} {
  const [category, setCategory] = useState(
    new CategoryData({
      type: ContainerType.CATEGORY,
      parentPaths: [],
      childPaths: [],
      path: '',
      userId: '',
      name: '',
      votes: {},
      creationTime: null,
    }),
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    try {
      if (!loading && category.getType() != ContainerType.CATEGORY) {
        throw new Error('ContainerData is not a Category!');
      }

      if (isEmpty(info.id) || isEmpty(info.loc)) {
        throw new Error('Cannot listen to container without any id.');
      }

      const unsubscribe = onContainerDataSnapshot<_CategoryData>(info, data => {
        setCategory(CategoryData.makeFromListener(data));
        setLoading(false);
      }, error => {
        setError(error);
        setLoading(false);
      });

      return () => {
        unsubscribe();
      };
    } catch (error: any) {
      console.error(error);
      setError(error);
    }
  }, []);

  return { category: category, loading: loading, error: error };
}

/**
 * Represents a group with a unique identifier.
 * 
 * @class Group
 * @property {string} groupId - The unique identifier for the group.
 * 
 * @method constructor - Initializes a new instance of the Group class.
 * @param {string} groupId - The unique identifier for the group.
 * 
 * @method getGroupId - Retrieves the unique identifier of the group.
 * @returns {string} The unique identifier of the group.
 * 
 * @method getLink - Generates a URL link to the group.
 * @returns {string} The URL link to the group.
 * 
 * @method static generateMemberPassword - Generates a password for a group member.
 * @returns {string} A newly generated password.
 */
export class Group {
  private readonly groupId: string;

  public constructor(groupId: string) {
    this.groupId = groupId;
  }

  public getGroupId(): string {
    return this.groupId;
  }

  public getLink(): string {
    return `https://app.lucid-mind.eu/group/${this.groupId}`;
  }

  public static generateMemberPassword(): string {
    return make_uuid();
  }

}
