import { Colors, View } from 'react-native-ui-lib';
import { convertRouteParamsToInfo } from '../helperFunctions';
import { ArgumentCard } from './Containers';
import { FirestoreConstants, navigationProps, ElementPath, ContainerType } from '../typings/types';
import { ScrollView } from 'react-native';
import React, { useEffect, useState } from 'react';
import { useIsMobile } from '../MediaQueries';
import { strings } from '../localization/localization.web';
import { DocumentData, doc, getDoc, getFirestore } from 'firebase/firestore';
import { NotYetImplementedDialog } from './Dialogs';
import { ButtonPrimary } from './Buttons';
import { useArgumentListener } from '../dataModel';
import { useUserState } from '../firebaseWrapper/firebaseWrapper';

export function ArgumentScreen({
  navigation,
  route,
}: navigationProps): JSX.Element {
  const groupId = route.params.groupId;
  const memberId = route.params.member;
  const parentInfo: ElementPath = convertRouteParamsToInfo(route.params) as ElementPath;
  const { argument, loading, error } = useArgumentListener(parentInfo);
  const [conclusionInfo, setConclusionInfo] = useState<ElementPath>(undefined);
  const isMobile = useIsMobile();
  const [adminLoggedIn, setAdminLoggedIn] = React.useState(false);
  const [group, setGroup] = React.useState<DocumentData>();
  const [user] = useUserState();

  useEffect(() => {
    if (parentInfo.type === ContainerType.ARGUMENT && argument) {
      setConclusionInfo(argument.getParentInfos()[0]);
    }
  }, [argument]);

  useEffect(() => {
    if (group && user) {
      setAdminLoggedIn(group.admin === user.uid);
    }
  }, [group, user]);

  useEffect(() => {
    const documentReference = doc(getFirestore(), FirestoreConstants.GROUPS, groupId);
    getDoc(documentReference).then((documentSnapshot) => {
      if (documentSnapshot.exists()) {
        setGroup({ ...documentSnapshot.data(), id: documentSnapshot.id });
      } else {
        console.log('No such document!');
      }
    }).catch((error) => {
      console.log('Error getting document:', error);
    });
  }, []);

  return (
    <View
      flex
      style={{
        backgroundColor: Colors.primaryBG
      }}
    >
      <View flex
        style={{
          backgroundColor: isMobile ? 'transparent' : Colors.secondaryBG,
          borderRadius: 20,
          marginHorizontal: isMobile ? 0 : 5,
          marginVertical: 5,
          overflow: 'hidden',
        }}
      >
        {parentInfo.type === ContainerType.ARGUMENT && argument && conclusionInfo ?
          <View flex style={{ backgroundColor: 'transparent' }}>
            <View style={{
              flex: 1,
              backgroundColor: Colors.secondaryBG,
              borderRadius: 20,
              margin: 0,
              marginHorizontal: isMobile ? 0 : 20,
              marginBottom: 0,
              paddingTop: isMobile ? 10 : 20,
              paddingHorizontal: isMobile ? 10 : 0
            }}>
              <ScrollView showsVerticalScrollIndicator={false}>
                <ArgumentCard
                  navigation={navigation}
                  info={parentInfo}
                  argument={argument}
                  groupId={groupId}
                  memberId={memberId}
                  groupAdminLoggedIn={adminLoggedIn}
                />
                <View marginL-20 marginT-10 marginR-10 marginB-60>
                  <ButtonPrimary label={strings.critisize} onPress={() => NotYetImplementedDialog(strings.critisize, strings)} />
                </View>
              </ScrollView>
            </View>
          </View>
          : null}
      </View >
    </View >
  );
}