import { Badge, Colors, Text, TextField, View } from 'react-native-ui-lib';
import { FirestoreConstants, navigationProps, ElementPath, PathConstants, ContainerType } from '../typings/types';
import React, { useEffect } from 'react';
import { useIsMobile } from '../MediaQueries';
import { strings } from '../localization/localization.web';
import { DocumentData, arrayRemove, collection, deleteDoc, doc, getDoc, getDocs, getFirestore, limit, query, setDoc, where } from 'firebase/firestore';
import { IconButton } from './Buttons';
import { getGroupStatementsQuery, useUserState } from '../firebaseWrapper/firebaseWrapper';
import { MaterialIcons } from '@expo/vector-icons';
import { FloatingActionButton } from './FloatingActionButton';
import { LazyList } from './FeedList';
import { alert } from './Alert';

export function TopicScreen({
  navigation,
  route,
}: navigationProps): JSX.Element {
  const parentInfo: ElementPath = {
    loc: PathConstants.GROUP,
    id: route.params.group,
    type: ContainerType.GROUP,
    groupId: route.params.group
  }
  const groupId = route.params.group;
  const topicId = route.params.id;
  const memberId = route.params.member;
  const isMobile = useIsMobile();
  const [group, setGroup] = React.useState<DocumentData>();
  const [topic, setTopic] = React.useState<DocumentData>();
  const [topicNameEditable, setTopicNameEditable] = React.useState(false);
  const [newTopicName, setNewTopicName] = React.useState(topic?.name);
  const [user, loading] = useUserState();
  const [adminLoggedIn, setAdminLoggedIn] = React.useState(false);

  useEffect(() => {
    if (group && user) {
      setAdminLoggedIn(group.admin === user.uid);
    }
  }, [group, user]);

  useEffect(() => {
    const documentReference = doc(getFirestore(), FirestoreConstants.GROUPS, groupId);
    getDoc(documentReference).then((documentSnapshot) => {
      if (documentSnapshot.exists()) {
        // console.log('Group data:', documentSnapshot.data());
        setGroup({ ...documentSnapshot.data(), id: documentSnapshot.id });
      } else {
        console.log('No such document!');
      }
    }).catch((error) => {
      console.log('Error getting document:', error);
    });
  }, []);

  useEffect(() => {
    const topicReference = doc(getFirestore(), FirestoreConstants.GROUPS, groupId, FirestoreConstants.TOPICS, topicId);
    getDoc(topicReference).then((documentSnapshot) => {
      if (documentSnapshot.exists()) {
        // console.log('Topic data:', documentSnapshot.data());
        setTopic({ ...documentSnapshot.data(), id: documentSnapshot.id });
      } else {
        console.log('No such document!');
      }
    }).catch((error) => {
      console.log('Error getting document:', error);
    });
  }, []);

  const handleTopicNameChange = (newName: string) => {
    const topicReference = doc(getFirestore(), FirestoreConstants.GROUPS, groupId, FirestoreConstants.TOPICS, topicId);
    if (newName && newName !== topic.name) {
      setDoc(topicReference, { name: newName }, { merge: true });
      setTopic({ ...topic, name: newName });
    }
    setTopicNameEditable(false);
  }

  const deleteTopic = () => {
    let confirmed = false;
    alert(strings.delete_topic, strings.delete_topic_alert, strings.cancel, null, strings.remove_member, () => { confirmed = true });
    if (confirmed) {
      const topicReference = doc(getFirestore(), FirestoreConstants.GROUPS, groupId, FirestoreConstants.TOPICS, topicId);
      const statementsWithToptic = query(collection(getFirestore(), FirestoreConstants.GROUPS, groupId, FirestoreConstants.STATEMENTS), where('topics', 'array-contains', topicId));
      getDocs(statementsWithToptic).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          console.log(doc.id, ' => ', doc.data());
          setDoc(doc.ref, { topics: arrayRemove(topicId) }, { merge: true });
        });
      }
      ).catch((error) => {
        console.log('Error getting documents: ', error);
      });
      deleteDoc(topicReference);
      navigation.goBack();
    }
  }

  return (
    <View
      flex
      style={{
        backgroundColor: Colors.primaryBG
      }}
    >
      <View flex
        style={{
          backgroundColor: isMobile ? 'transparent' : Colors.secondaryBG,
          borderRadius: isMobile ? 0 : 20,
          marginHorizontal: isMobile ? 0 : 5,
          marginVertical: 5,
          overflow: 'hidden',
        }}
      >
        {topic ?
          <View flex style={{ marginHorizontal: isMobile ? 0 : 10, marginVertical: 5, marginBottom: 0 }}>
            <View flex style={{ backgroundColor: 'transparent', borderRadius: 20, paddingVertical: isMobile ? 0 : 20 }}>
              <View paddingB-10 style={{ paddingHorizontal: isMobile ? 15 : 25 }}>
                {topicNameEditable ?
                  <View row centerV marginB-8>
                    <TextField
                      placeholder={topic.name}
                      value={newTopicName || topic.name}
                      onChangeText={currentName => setNewTopicName(currentName)}
                      style={{ color: Colors.textPrimary }}
                      text40
                    />
                    <IconButton
                      label={strings.cancel}
                      onPress={() => setTopicNameEditable(false)}
                      icon={<MaterialIcons name="close" size={25} color={Colors.iconPrimary} />}
                    />
                    <IconButton
                      label={strings.save}
                      onPress={() => handleTopicNameChange(newTopicName)}
                      icon={<MaterialIcons name="check" size={25} color={Colors.iconPrimary} />}
                    />
                  </View>
                  : <Text marginB-8 text40 style={{ color: Colors.textPrimary }}>
                    {topic.name}
                  </Text>
                }
                <View row>
                  <Badge
                    customElement={<MaterialIcons name="people" size={16} color={Colors.iconSecondary} />}
                    label={group.name} size={20} backgroundColor={Colors.quaternaryBG} labelStyle={{ color: Colors.textSecondary }}
                    onPress={() => navigation.goBack()}
                  />
                  {!topicNameEditable && adminLoggedIn ?
                    <Badge
                      customElement={<MaterialIcons name="mode-edit" size={16} color={Colors.iconSecondary} />}
                      label={strings.edit_topic_name} size={20} backgroundColor={Colors.quaternaryBG} labelStyle={{ color: Colors.textSecondary }}
                      onPress={() => setTopicNameEditable(true)}
                    />
                    : null
                  }
                  {topicNameEditable && adminLoggedIn ?
                    <Badge
                      customElement={<MaterialIcons name="delete" size={16} color={Colors.iconSecondary} />}
                      label={strings.delete_topic} size={20} backgroundColor={Colors.quaternaryBG} labelStyle={{ color: Colors.textSecondary }}
                      onPress={() => deleteTopic()}
                    />
                    : null
                  }
                </View>
              </View>
              {group ?
                <LazyList
                  navigation={navigation}
                  route={route}
                  query={getGroupStatementsQuery(group.id, topic.id, true)}
                  location={PathConstants.GROUP}
                  groupId={group.id}
                  memberId={memberId}
                  groupAdminLoggedIn={adminLoggedIn}
                  header={strings.main_statements}
                />
                : null}
            </View>
          </View>
          : null
        }
      </View >
      <FloatingActionButton
        navigation={navigation}
        parentInfo={parentInfo}
        possibleTypes={[ContainerType.STATEMENT]}
        groupId={groupId}
        topicId={topicId}
        memberId={memberId}
      />
    </View >
  );
}