import React from 'react';
import { Button, Colors, Text, View } from 'react-native-ui-lib';
import { getFeedElementsQuery, proposeArgument, useLazyElementsLoader } from '../firebaseWrapper/firebaseWrapper';
import { ContainerType, PathConstants, StanceType, navigationProps } from '../typings/types';
import { List, TopicList, TopicListPublic } from './StatementList';
import { Pressable, TextInput } from 'react-native';
import { styles } from '../styles';
import { strings } from '../localization/localization.web';
import LinearGradient from 'react-native-linear-gradient';
import { useIsMobile } from '../MediaQueries';

export function FeedList({ navigation, route }: navigationProps): JSX.Element {

  const isMobile = useIsMobile();

  return (
    <View
      flex
      style={{
        backgroundColor: Colors.primaryBG
      }}
    >
      <View flex
        style={{
          backgroundColor: isMobile ? 'transparent' : Colors.secondaryBG,
          borderRadius: 20,
          marginHorizontal: isMobile ? 0 : 5,
          marginVertical: 5,
          overflow: 'hidden',
        }}
      >
        <View style={{ marginLeft: isMobile ? 10 : 25, marginTop: isMobile ? 0 : 20 }}>
          <TopicListPublic />
        </View>
        <LazyList
          navigation={navigation}
          route={route}
          query={getFeedElementsQuery()}
          location={PathConstants.PUBLIC}
        />
      </View>
    </View>
  );
}

type LazyListProps = {
  navigation: any;
  route: any;
  query: any;
  location: PathConstants;
  groupId?: string;
  memberId?: string;
  groupAdminLoggedIn?: boolean;
  header?: string;
};

export function LazyList({
  navigation,
  route,
  query,
  location,
  groupId,
  memberId,
  groupAdminLoggedIn,
  header
}: LazyListProps): JSX.Element {
  const parentInfo = location === PathConstants.GROUP ?
    {
      loc: PathConstants.GROUP,
      id: groupId,
      type: ContainerType.GROUP,
      groupId: groupId

    }
    :
    route.params?.parentInfo || '';
  const {
    infos,
    loading,
    error,
    refresh,
    loadMoreElements: loadMoreElements,
  } = useLazyElementsLoader(query, parentInfo, location, groupId);

  if (error) {
    return (
      <View flex center>
        <Text>Error: {error.message}</Text>
      </View>
    );
  }

  const loadMoreEntriesWrapper = () => {
    loadMoreElements(10);
  };

  return (
    <>
      {infos && (
        <List
          infoList={infos}
          parentInfo={parentInfo}
          navigation={navigation}
          loading={loading}
          onRefresh={refresh}
          onEndReached={loadMoreEntriesWrapper}
          header={header}
          groupId={groupId}
          memberId={memberId}
          groupAdminLoggedIn={groupAdminLoggedIn}
        />
      )}
    </>
  );
}
