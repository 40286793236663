import { NavigationProp, RouteProp, useFocusEffect } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import DraggableFlatList, {
  ScaleDecorator,
} from 'react-native-draggable-flatlist';
import { Layout } from 'react-native-reanimated';
import { Colors, Text, View } from 'react-native-ui-lib';
import {
  useContainerListener,
} from '../dataModel';
import {
  convertInfoToPath,
  convertPathToInfo,
  convertRouteParamsToInfo,
  getContainerTypeFromPath,
  isEditingAllowed,
} from '../helperFunctions';
import { ContainerType, StanceType, ElementPath, navigationProps, FirestoreConstants, PathConstants } from '../typings/types';
import { Container, Statement } from './Containers';
import { FloatingActionButton } from './FloatingActionButton';
import { useIsMobile, useIsTablet } from '../MediaQueries';
import { Ionicons } from '@expo/vector-icons';
import LinearGradient from 'react-native-linear-gradient';
import { THREAD_WIDTH } from '../styles';
import { strings } from '../localization/localization.web';
import { NothingHereInformation } from './Alert';
import { Footer } from './Footer';
import { ScrollView } from 'react-native-gesture-handler';
import { Dimensions } from 'react-native';
import { doc, DocumentData, getDoc, getFirestore } from 'firebase/firestore';
import { useUserState } from '../firebaseWrapper/firebaseWrapper';

export function StatementThread({
  navigation,
  route,
}: navigationProps): JSX.Element {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const memberId = route.params.member;
  const groupId = route.params.groupId;
  const [group, setGroup] = React.useState<DocumentData>();
  const [adminLoggedIn, setAdminLoggedIn] = React.useState(false);
  const [user, loading] = useUserState();

  useEffect(() => {
    if (groupId !== undefined) {
      const documentReference = doc(getFirestore(), FirestoreConstants.GROUPS, groupId);
      getDoc(documentReference).then((documentSnapshot) => {
        if (documentSnapshot.exists()) {
          setGroup({ ...documentSnapshot.data(), id: documentSnapshot.id });
        } else {
          console.log('No such document!');
        }
      }).catch((error) => {
        console.log('Error getting document:', error);
      });
    }
  }, []);

  useEffect(() => {
    if (group && user) {
      setAdminLoggedIn(group.admin === user.uid);
    }
  }, [group, user]);

  return (
    <>
      {isMobile || isTablet ? (
        <StatementThreadMobile
          navigation={navigation}
          route={route}
          memberId={memberId}
          groupId={groupId}
          groupAdminLoggedIn={adminLoggedIn}
        />
      ) : (
        <StatementThreadDesktop
          navigation={navigation}
          route={route}
          memberId={memberId}
          groupId={groupId}
          groupAdminLoggedIn={adminLoggedIn}
        />
      )}
    </>
  );
}

function StatementThreadMobile({
  navigation,
  route,
  memberId,
  groupId,
  groupAdminLoggedIn
}: StatementThreadProps): JSX.Element {
  const info: ElementPath = convertRouteParamsToInfo(route.params) as ElementPath;

  const { container, loading, error } = useContainerListener(info);
  const [dataList, setDataList] = React.useState([
    { [convertInfoToPath(info)]: StanceType.NEUTRAL },
  ]);
  const [transitionFinished, setTransitionFinished] = useState(false);

  useFocusEffect(() => {
    if (!transitionFinished) {
      setTransitionFinished(true);
    }
  });

  useEffect(() => {
    if (container && transitionFinished) {
      setDataList([{ [convertInfoToPath(info)]: StanceType.NEUTRAL }, ...container.getChildData()]);
    }
  }, [container, transitionFinished]);

  if (error) {
    return (
      <View flex center>
        <Text>Error: {error.message}</Text>
      </View>
    );
  }

  const onDragEnd = ({ data }) => {
    if (!isEditingAllowed(container)) {
      return;
    }
    container.setChildData(
      data.filter(item => Object.keys(item)[0] !== convertInfoToPath(info)),
    );
    setDataList([
      { [convertInfoToPath(info)]: StanceType.NEUTRAL },
      ...data.filter(item => Object.keys(item)[0] !== convertInfoToPath(info)),
    ]);
  };

  const renderItem = ({ item, getIndex, drag, isActive }) => {
    if (getIndex() === 0) {
      return <>
        {dataList.length > 1 ? <ArgumentThreadHeader stance={StanceType.NEUTRAL} /> : null}
      </>;
    } else {
      return (
        <LineToSideOfViewMobile
          key={Object.keys(item)[0]}
          stance={item[Object.keys(item)[0]]}
          lastLine={getIndex() === dataList.length - 1 && !isActive}
          isActive={isActive}
        >
          <ScaleDecorator>
            {/* {getIndex() === 1 && !isActive ?
              <View marginL-20>
                <ThreadConnector stance={dataList[getIndex()][Object.keys(dataList[getIndex()])[0]]}/>
              </View>
              : null} */}
            <View marginH-0 marginL-0>
              <Container
                info={{ ...info, ...convertPathToInfo(Object.keys(item)[0]) }}
                parentInfo={info}
                navigation={navigation}
                drag={isEditingAllowed(container) ? drag : undefined}
                stance={item[Object.keys(item)[0]]}
                displayAsCondition={true}
                groupId={groupId}
                groupAdminLoggedIn={groupAdminLoggedIn}
                memberId={memberId}
              />
              {/* {!(getIndex() === dataList.length - 1 && !isActive) ?
                <View style={{width: 5, zIndex: -100}}>
                  <ThreadConnector stance={dataList[getIndex()+1][Object.keys(dataList[getIndex()+1])[0]]}/>
                </View>
              : null} */}
            </View>
          </ScaleDecorator>
        </LineToSideOfViewMobile>
      );
    }
  };

  return (
    <View flex style={{ backgroundColor: Colors.primaryBG }}>
      <View style={{
        flex: 1,
        backgroundColor: Colors.secondaryBG,
        borderRadius: 15,
        marginHorizontal: 0,
        marginVertical: 5,
        overflow: 'hidden',
      }}>
        <ParentViewMobile
          navigation={navigation}
          route={route}
          groupId={'groupId' in info ? info.groupId : undefined}
          memberId={memberId}
          groupAdminLoggedIn={groupAdminLoggedIn}
        />
        {dataList.length > 1 ?
          <DraggableFlatList
            showsVerticalScrollIndicator={false}
            dragItemOverflow={true}
            keyboardShouldPersistTaps="handled"
            data={dataList}
            onDragEnd={onDragEnd}
            containerStyle={{ flex: 1 }}
            itemLayoutAnimation={Layout}
            renderPlaceholder={() => (
              <LineToSideOfViewMobile stance={StanceType.NEUTRAL} lastLine={false} />
            )}
            refreshing={loading}
            keyExtractor={item => Object.keys(item)[0]}
            stickyHeaderIndices={[0]}
            stickyHeaderHiddenOnScroll={true}
            renderItem={renderItem}
            ListFooterComponent={() =>
              <>
                {dataList.length < 3 ? <View style={{ height: Dimensions.get('window').height }} /> : null}
                <Footer navigation={navigation} />
              </>
            }
          />
          : null
        }
        {!loading && container.getChildData().length < 1 ?
          <ScrollView showsVerticalScrollIndicator={false}>
            <NothingHereInformation text={strings.no_arguments_info} />
            <View marginT-100 />
            <Footer navigation={navigation} />
          </ScrollView>
          : null
        }
      </View>
      <FloatingActionButton
        navigation={navigation}
        parentInfo={info}
        possibleTypes={
          container.getType() === ContainerType.CATEGORY
            ? [
              ContainerType.CATEGORY,
              ContainerType.STATEMENT,
            ]
            : container.getType() === ContainerType.STATEMENT ?
              [
                ContainerType.ARGUMENT,
                ContainerType.SOURCE,
              ]
              : container.getType() === ContainerType.SOURCE ?
                [
                  ContainerType.ARGUMENT,
                ]
                :
                [
                  ContainerType.STATEMENT,
                  ContainerType.SOURCE,
                ]
        }
        groupId={'groupId' in info ? info.groupId : undefined}
        memberId={memberId}
        topicId={container.getTopics()[0]}
      />
    </View>
  );
}

type StatementThreadProps = {
  navigation: NavigationProp<any>;
  route: RouteProp<any, any>;
  memberId?: string;
  groupId?: string;
  groupAdminLoggedIn?: boolean;
}

function StatementThreadDesktop({
  navigation,
  route,
  memberId,
  groupId,
  groupAdminLoggedIn
}: StatementThreadProps): JSX.Element {
  const info: ElementPath = convertRouteParamsToInfo(route.params);

  const { container, loading, error } = useContainerListener(info);
  const [proList, setProList] = React.useState([]);
  const [conList, setConList] = React.useState([]);
  const [transitionFinished, setTransitionFinished] = useState(false);

  useFocusEffect(() => {
    if (!transitionFinished) {
      setTransitionFinished(true);
    }
  });

  useEffect(() => {
    if (container && transitionFinished) {
      setProList(container.getChildData().filter(item => item[Object.keys(item)[0]] === StanceType.SUPPORT));
      setConList(container.getChildData().filter(item => item[Object.keys(item)[0]] === StanceType.OPPOSE));
    }
  }, [container, transitionFinished]);

  if (error) {
    return (
      <View flex center>
        <Text>Error: {error.message}</Text>
      </View>
    );
  }

  const onDragEndPro = ({ data: newList, from, to }) => {
    // TODO: Needs a different logic for desktop
    // if (!isEditingAllowed(container)) {
    //   return;
    // }

    // let childData = container.getChildData();

    // const from_index = childData.findIndex(item => Object.keys(item)[0] === Object.keys(newList[to])[0]);
    // let to_index = 0;
    // try {
    //   to_index = childData.findIndex(item => Object.keys(item)[0] === Object.keys(newList[to-1])[0]);
    // } catch (e) {
    //   to_index = 0;
    // }

    // const from_child = childData.splice(from, 1)[0];
    // childData.splice(to, 0, from_child);

    // setProList(newList);
    // container.setChildData(childData);
  };

  const onDragEndCon = ({ data: newList, from, to }) => {
    // TODO: Needs a different logic for desktop
    // if (!isEditingAllowed(container)) {
    //   return;
    // }

    // let childData = container.getChildData();

    // const from_index = childData.findIndex(item => Object.keys(item)[0] === Object.keys(newList[to])[0]);
    // let to_index = 0;
    // try {
    //   to_index = childData.findIndex(item => Object.keys(item)[0] === Object.keys(newList[to-1])[0]);
    // } catch (e) {
    //   to_index = 0;
    // }

    // const from_child = childData.splice(from, 1)[0];
    // childData.splice(to, 0, from_child);

    // setProList(newList);
    // container.setChildData(childData);
  };

  const renderProItem = ({ item, getIndex, drag, isActive }) => {
    const parentInfo = info;
    let itemInfo: any;
    if ('groupId' in parentInfo) {
      itemInfo = {
        loc: PathConstants.GROUP,
        id: Object.keys(item)[0],
        type: getContainerTypeFromPath(Object.keys(item)[0]),
        groupId: parentInfo.groupId
      };
    } else {
      itemInfo = convertPathToInfo(Object.keys(item)[0])
    }
    return (
      <LineToSideOfViewDesktop
        key={Object.keys(item)[0]}
        stance={StanceType.SUPPORT}
        lastLine={getIndex() === proList.length - 1 && !isActive}
        isActive={isActive}>
        <ScaleDecorator>
          <View marginH-0 marginL-0>
            <View style={{ borderLeftWidth: 5, borderColor: Colors.greenBG, height: 0 }} />
            <Container
              info={{ ...info, ...itemInfo }}
              parentInfo={info}
              navigation={navigation}
              drag={isEditingAllowed(container) ? drag : undefined}
              stance={item[Object.keys(item)[0]]}
              displayAsCondition={true}
              groupId={groupId}
              memberId={memberId}
              groupAdminLoggedIn={groupAdminLoggedIn}
            />
            {/* {!(getIndex() === proList.length - 1 && !isActive) ?
            <ThreadConnector stance={StanceType.SUPPORT}/>
            : null} */}
          </View>
        </ScaleDecorator>
      </LineToSideOfViewDesktop>
    );
  };

  const renderConItem = ({ item, getIndex, drag, isActive }) => {
    return (
      <LineToSideOfViewDesktop
        key={Object.keys(item)[0]}
        stance={StanceType.OPPOSE}
        lastLine={getIndex() === conList.length - 1 && !isActive}
        isActive={isActive}>
        <ScaleDecorator>
          <View marginH-0 marginL-0>
            <View style={{ borderLeftWidth: 5, borderColor: Colors.redBG, height: 0 }} />
            <Container
              info={{ ...info, ...convertPathToInfo(Object.keys(item)[0]) }}
              parentInfo={info}
              navigation={navigation}
              drag={isEditingAllowed(container) ? drag : undefined}
              stance={item[Object.keys(item)[0]]}
              displayAsCondition={true}
              groupId={groupId}
              memberId={memberId}
              groupAdminLoggedIn={groupAdminLoggedIn}
            />
            {/* {!(getIndex() === conList.length - 1 && !isActive) ?
            <ThreadConnector stance={StanceType.OPPOSE}/>
            : null} */}
          </View>
        </ScaleDecorator>
      </LineToSideOfViewDesktop>
    );
  };

  return (
    <View flex style={{ backgroundColor: Colors.primaryBG }}>
      <View style={{
        flex: 1,
        backgroundColor: Colors.secondaryBG,
        borderRadius: 15,
        margin: 5,
        overflow: 'hidden',
      }}>
        <ParentViewDesktop
          navigation={navigation}
          route={route}
          groupId={'groupId' in info ? info.groupId : undefined}
          memberId={memberId}
          groupAdminLoggedIn={groupAdminLoggedIn}
        />
        <View flex row>
          {proList.length > 0 ?
            <DraggableFlatList
              showsVerticalScrollIndicator={false}
              dragItemOverflow={true}
              keyboardShouldPersistTaps="handled"
              data={proList}
              onDragEnd={onDragEndPro}
              containerStyle={{ flex: 1 }}
              itemLayoutAnimation={Layout}
              renderPlaceholder={() => (
                <LineToSideOfViewDesktop stance={StanceType.SUPPORT} lastLine={false} />
              )}
              refreshing={loading}
              keyExtractor={item => Object.keys(item)[0]}
              renderItem={renderProItem}
              ListFooterComponent={() => <View style={{ height: 75 }} />}
              ListHeaderComponent={container.getChildData().filter(item => item[Object.keys(item)[0]] === StanceType.SUPPORT).length > 0 ?
                <ArgumentThreadHeader
                  stance={StanceType.SUPPORT}
                />
                : null
              }
              stickyHeaderIndices={[0]}
            />
            : null
          }
          {conList.length > 0 ?
            <DraggableFlatList
              showsVerticalScrollIndicator={false}
              dragItemOverflow={true}
              keyboardShouldPersistTaps="handled"
              data={conList}
              onDragEnd={onDragEndCon}
              containerStyle={{ flex: 1 }}
              itemLayoutAnimation={Layout}
              renderPlaceholder={() => (
                <LineToSideOfViewDesktop stance={StanceType.OPPOSE} lastLine={false} />
              )}
              refreshing={loading}
              keyExtractor={item => Object.keys(item)[0]}
              renderItem={renderConItem}
              ListFooterComponent={() => <View style={{ height: 75 }} />}
              ListHeaderComponent={container.getChildData().filter(item => item[Object.keys(item)[0]] === StanceType.OPPOSE).length > 0 ?
                <ArgumentThreadHeader
                  stance={StanceType.OPPOSE}
                />
                : null
              }
              stickyHeaderIndices={[0]}
            />
            : null
          }
          {!loading
            && container.getChildData().filter(item => item[Object.keys(item)[0]] === StanceType.SUPPORT).length === 0
            && container.getChildData().filter(item => item[Object.keys(item)[0]] === StanceType.OPPOSE).length === 0 ?
            <NothingHereInformation text={strings.no_arguments_info} />
            : null
          }
        </View>
      </View>
      <FloatingActionButton
        navigation={navigation}
        parentInfo={info}
        possibleTypes={
          container.getType() === ContainerType.CATEGORY
            ? [
              ContainerType.CATEGORY,
              ContainerType.STATEMENT,
            ]
            : container.getType() === ContainerType.STATEMENT ?
              [
                ContainerType.ARGUMENT,
                ContainerType.SOURCE,
              ]
              : container.getType() === ContainerType.SOURCE ?
                [
                  ContainerType.ARGUMENT,
                ]
                :
                [
                  ContainerType.STATEMENT,
                  ContainerType.SOURCE,
                ]
        }
        groupId={'groupId' in info ? info.groupId : undefined}
        memberId={memberId}
        topicId={container.getTopics()[0]}
      />
    </View>
  );
}

function ParentViewMobile(
  {
    navigation,
    route,
    groupId,
    memberId,
    groupAdminLoggedIn
  }: StatementThreadProps
): JSX.Element {
  const info = convertRouteParamsToInfo(route.params);
  const parentInfo = null;

  return (
    <View style={{
      backgroundColor: Colors.secondaryBG,
      borderRadius: 15,
    }}>
      <View margin-10 marginB-0 style={{ flexDirection: 'column-reverse' }}>
        <Statement
          info={info}
          parentInfo={parentInfo}
          navigation={navigation}
          drag={undefined}
          showStanceIndicator={false}
          groupId={groupId}
          memberId={memberId}
          groupAdminLoggedIn={groupAdminLoggedIn}
        />
      </View>
    </View>
  );
}

function ParentViewDesktop({ navigation, route, groupId, memberId, groupAdminLoggedIn }: StatementThreadProps): JSX.Element {
  const info = convertRouteParamsToInfo(route.params);
  const parentInfo = null;

  return (
    <View style={{
      backgroundColor: Colors.secondaryBG,
      borderRadius: 15,
    }}>
      <View margin-9 marginB-0 style={{ flexDirection: 'column-reverse' }}>
        <Statement
          info={info}
          parentInfo={parentInfo}
          navigation={navigation}
          drag={undefined}
          showStanceIndicator={false}
          groupId={groupId}
          memberId={memberId}
          groupAdminLoggedIn={groupAdminLoggedIn}
        />
      </View>
    </View>
  );
}

type LineProps = {
  stance: StanceType;
  lastLine: boolean;
  isActive?: boolean;
  children?: JSX.Element;
};

function LineToSideOfViewMobile(props: LineProps): JSX.Element {
  return (
    <LineToSideOfViewDesktop stance={props.stance} lastLine={props.lastLine} children={props.children} isActive={props.isActive} />
  );
}
export function LineToSideOfViewDesktop(props: LineProps): JSX.Element {
  const isMobile = useIsMobile();
  const color = isMobile ? Colors.iconSecondary :
    props.stance === StanceType.SUPPORT ? Colors.greenBG : Colors.redBG;

  return (
    <View flex style={{ flexDirection: 'row-reverse', marginLeft: 9.75, marginRight: 10 }}>
      <View flex marginT-5 marginB-5 style={{ zIndex: props.isActive ? 1 : 0 }}>
        {props.children}
      </View>
      <View style={{ marginLeft: 10 }}>
        <View
          width={THREAD_WIDTH}
          style={{
            width: THREAD_WIDTH,
            height: 5,
            left: 0,
            backgroundColor: color
          }}
        />
        {props.children ? (
          <View
            style={{
              width: 15,
              height: 20,
              left: 0,
              marginRight: -5,
              borderBottomLeftRadius: 13,
              borderBottomColor: color,
              borderBottomWidth: THREAD_WIDTH,
              borderLeftColor: color,
              borderLeftWidth: THREAD_WIDTH,
            }} />
        ) : undefined}
        {/* <View style={{height: 25}}/> */}
        <View
          flex
          width={THREAD_WIDTH}
          style={{
            marginTop: -10,
            width: THREAD_WIDTH,
            backgroundColor: props.lastLine ? 'transparent' : color,
          }} />
      </View>
    </View>
  );
}

type StanceProp = {
  stance: StanceType;
}
export function ArgumentThreadHeader({ stance }: StanceProp): JSX.Element {
  return (
    <View marginL-9 bg-secondaryBG>
      <ImplicationIndicator stance={stance} />
    </View>
  )
}

export function ImplicationIndicator({ stance }: StanceProp): JSX.Element {

  const arrowHeadSize = 15;
  const color = stance === StanceType.SUPPORT ? Colors.greenBG : stance === StanceType.OPPOSE ? Colors.redBG : Colors.iconSecondary;

  return (
    <>
      <Ionicons
        name="md-triangle"
        size={arrowHeadSize}
        color={color}
        style={{ padding: 0, marginLeft: THREAD_WIDTH + 2, marginBottom: 0, marginTop: -2, position: 'relative' }}
      />
      <View
        style={{
          marginLeft: THREAD_WIDTH + arrowHeadSize / 2 + 1.25,
          marginTop: -5,
          borderLeftWidth: THREAD_WIDTH,
          borderColor: color,
          height: 10,
          width: 5
        }}
      />
    </>
  )
}

export function ThreadConnector({ stance }: StanceProp): JSX.Element {

  const color = stance === StanceType.SUPPORT ? Colors.greenBG : stance === StanceType.OPPOSE ? Colors.redBG : Colors.iconSecondary;

  return (
    <LinearGradient
      locations={[0.5, 1]}
      colors={[Colors.iconSecondary, color]}
      start={{ x: 0.0, y: 0.0 }}
      end={{ x: 0.0, y: 1.0 }}
      style={{ marginTop: -20, width: THREAD_WIDTH, zIndex: -1 }}
    >
      <View style={{ marginLeft: 5, height: 40, width: THREAD_WIDTH }} />
    </LinearGradient>
  )
}