import React, { useContext, useEffect, useState } from 'react';
// import Toast from 'react-native-simple-toast';
import {
  Colors,
  SkeletonView,
  Text,
  TouchableOpacity,
  View,
} from 'react-native-ui-lib';
import {
  ContainerData,
  useCategoryListener,
  useContainerListener,
  useSourceListener,
  useArgumentListener,
  useStatementListener,
} from '../dataModel';
import { LocalizedStringsContext } from '../localization/localization.native';

import { ActionSheet } from 'react-native-ui-lib';

import * as Clipboard from 'expo-clipboard';
import { ListRenderItemInfo, Pressable, Share, TouchableHighlight } from 'react-native';
import { Timestamp, UserName } from '../components/helperComponents.native';
import AddThickIcon from '../../assets/res/add_thick.svg';
import DownvoteIcon from '../../assets/res/arrow_vote_down.svg';
import UpvoteIcon from '../../assets/res/arrow_vote_up.svg';
import BookmarkIcon from '../../assets/res/bookmark.svg';
import MoreIcon from '../../assets/res/more.svg';
import RemoveThickIcon from '../../assets/res/remove_thick.svg';
import { publishSubtree, toggleBookmark, useUserState } from '../firebaseWrapper/firebaseWrapper';
import { IsMaintainerContext } from '../functions/IsMaintainerContext';
import { ContainerType, StanceType, VoteType, dbPath, ElementPath, ConflictType, ConflictSeverity, PathConstants } from '../typings/types';
import {
  ConflictModal,
  NotYetImplementedDialog,
  PublishModal,
  showDeleteDialog,
  showLinkDialog,
} from './Dialogs';
import { useIsMobile } from '../MediaQueries';
import { containerToLink, convertInfoToPath, convertInfoToRouteParams, locToUrlString, selectScreenFromInfo, typeToUrlString } from '../helperFunctions';
import { ConflictBadge } from './Badges';
import { THREAD_WIDTH, styles } from '../styles';
import { Ionicons } from '@expo/vector-icons';
import { Feather } from '@expo/vector-icons';
import { MaterialIcons } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import LinearGradient from 'react-native-linear-gradient';
import { ArgumentThreadHeader, ImplicationIndicator, LineToSideOfViewDesktop } from './StatementThread';
import { FlatList } from 'react-native-gesture-handler';
import { strings } from '../localization/localization.web';
import { LogInForFeatureAlert } from './Alert';

export type containerProps = {
  info: ElementPath;
  parentInfo: ElementPath;
  navigation: any;
  drag?: any;
  stance?: StanceType;
  active?: boolean;
  showOptions?: boolean;
  showHeader?: boolean;
  displayAsCondition?: boolean;
  showStanceIndicator?: boolean;
  groupId?: string;
  memberId?: string;
  groupAdminLoggedIn?: boolean;
};

export function ContainerSkeleton(): JSX.Element {
  return (
    <View
      style={{
        backgroundColor: Colors.quaternaryBG,
        elevation: 3,
        borderRadius: 15,
      }}>
      <View paddingL-10>
        <SkeletonView
          marginT-10 width={100} height={18} borderRadius={5}
          colors={[Colors.skeletonPrimary, Colors.skeletonSecondary, Colors.skeletonPrimary]}
        />
      </View>
      <View>
        <SkeletonView
          margin-10
          marginB-5
          width={250}
          height={20}
          borderRadius={2}
          colors={[Colors.skeletonPrimary, Colors.skeletonSecondary, Colors.skeletonPrimary]}
        />
      </View>
      <View>
        <SkeletonView
          margin-10
          marginT-5
          width={220}
          height={20}
          borderRadius={2}
          colors={[Colors.skeletonPrimary, Colors.skeletonSecondary, Colors.skeletonPrimary]}
        />
      </View>
      <View padding-10 flex row style={{ justifyContent: 'flex-end' }}>
        <SkeletonView spread height={20} width={20} borderRadius={12} colors={[Colors.skeletonPrimary, Colors.skeletonSecondary, Colors.skeletonPrimary]} />
      </View>
    </View>
  );
}

export function Container(props: containerProps): JSX.Element {
  const { container, loading, error } = useContainerListener(props.info);
  const displayAsCondition = props.displayAsCondition || false;

  if (error) {
    return <ErrorContainer error={error} />;
  } else if (loading) {
    return <ContainerSkeleton />;
  }

  if (container.getType() === ContainerType.STATEMENT) {
    if (displayAsCondition) {
      return <ConditionCardForStatement {...props} />
    } else {
      return <Statement {...props} />;
    }
  } else if (container.getType() === ContainerType.ARGUMENT) {
    return <PremisesCard {...props} />;
  } else if (container.getType() === ContainerType.SOURCE) {
    return <Source {...props} />;
  } else if (container.getType() === ContainerType.CATEGORY) {
    return <Category {...props} />;
  } else {
    throw new Error('Not applicable container type: ' + container.getType());
    return <></>;
  }
}

export function Statement({
  info,
  parentInfo,
  navigation,
  drag,
  stance,
  showHeader = true,
  groupId,
  memberId,
  groupAdminLoggedIn
}: containerProps) {
  const isMaintainer = useContext(IsMaintainerContext);
  const strings = useContext(LocalizedStringsContext);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { statement, loading, error } = useStatementListener(info);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showConflictModal, setShowConflictModal] = useState(false);
  const [user, userLoading] = useUserState();

  if (error) {
    return <ErrorContainer error={error} />;
  } else if (loading) {
    return <ContainerSkeleton />;
  }

  let actions = [];

  // Handle listener tries to load deleted statement.
  if (statement && statement.getData() === undefined) {
    return <></>;
  }

  actions = [
    {
      label: strings.conflicts,
      onPress: () => {
        setShowConflictModal(true);
      },
    },
    ...statement.isPersonal() ? [{
      label: strings.publish,
      onPress: () => {
        user.isAnonymous ?
          LogInForFeatureAlert() :
          setShowPublishModal(!showPublishModal)
      }
    }] : [],
    ...statement.isPersonal() || isMaintainer || groupAdminLoggedIn || statement.getUserId() === memberId ? [{
      label: strings.edit,
      onPress: () => {
        user.isAnonymous && !memberId ?
          LogInForFeatureAlert() :
          navigation.push('EditDialog', {
            ...info,
            statement: statement,
            parentLoc: parentInfo ? parentInfo.loc : undefined,
            parentType: parentInfo ? parentInfo.type : undefined,
            parentId: parentInfo ? parentInfo.id : undefined,
            groupId: groupId,
            memberId: memberId
          });
      },
    }] : [],
    ...statement.isPersonal() || isMaintainer || groupAdminLoggedIn || statement.getUserId() === memberId ? [{
      label: strings.delete,
      style: { backgroundColor: 'red' },
      onPress: () => {
        showDeleteDialog(() => statement.delete(), strings);
      },
    }] : [],
    ...statement.isPublic() ? [{
      label: strings.copy_link,
      onPress: () => {
        Clipboard.setStringAsync(containerToLink(statement));
      },
    }] : [],
    ...statement.isPublic() ? [{
      label: strings.share,
      onPress: () => {
        Share.share({
          message: containerToLink(statement),
        });
      },
    }] : [],
    ...statement.isPublic() ? [{
      label: strings.report,
      onPress: () => {
        NotYetImplementedDialog('Reporting', strings);
      },
    }] : [],
  ];

  let conflicts = [];

  const checkForConflicts = () => {
    if (statement.getType() === ContainerType.STATEMENT) {
      checkSupportingArguments();
      checkLanguageObjectivity();
      checkRelevantArguments();
    }
    if (statement.getType() === ContainerType.SOURCE) {
      checkSourceValidity();
    }
    if (statement.getType() === ContainerType.ARGUMENT) {

    }
    return conflicts;
  }

  const checkSupportingArguments = () => {
    const supportingArgumentsFound = statement?.getChildStances().includes(StanceType.SUPPORT);
    supportingArgumentsFound === true ?
      conflicts.push({
        type: ConflictType.SUPPORTING_ARGUMENTS_FOUND,
        severity: ConflictSeverity.NONE
      })
      :
      supportingArgumentsFound === false ?
        conflicts.push({
          type: ConflictType.SUPPORTING_ARGUMENTS_NOT_FOUND,
          severity: ConflictSeverity.CRITICAL
        })
        :
        conflicts.push({
          type: ConflictType.SUPPORTING_ARGUMENTS_NOT_CHECKED,
          severity: ConflictSeverity.MINOR
        });
  }

  const checkSourceValidity = () => {
    const sourceValid: any = -1;
    sourceValid === true ?
      conflicts.push({
        type: ConflictType.SOURCE_VALID,
        severity: ConflictSeverity.NONE
      })
      :
      sourceValid === false ?
        conflicts.push({
          type: ConflictType.SOURCE_NOT_VALID,
          severity: ConflictSeverity.MAJOR
        })
        :
        conflicts.push({
          type: ConflictType.SOURCE_NOT_CHECKED,
          severity: ConflictSeverity.MINOR
        });
  }

  const checkLanguageObjectivity = () => {
    const languageCheckResult: Number = -1;
    switch (languageCheckResult) {
      case 1:
        conflicts.push({
          type: ConflictType.LANGUAGE_OBJECTIVE,
          severity: ConflictSeverity.NONE
        })
        break;
      case 0:
        conflicts.push({
          type: ConflictType.LANGUAGE_NOT_OBJECTIVE,
          severity: ConflictSeverity.MINOR
        })
        break;
      default:
        conflicts.push({
          type: ConflictType.LANGUAGE_NOT_CHECKED,
          severity: ConflictSeverity.NONE
        })
        break;
    }
  }

  const checkRelevantArguments = () => {
    const relevantArgumentCheckResult: Number = -1;
    switch (relevantArgumentCheckResult) {
      case 1:
        conflicts.push({
          type: ConflictType.RELEVANT_ARGUMENTS_SEEN,
          severity: ConflictSeverity.NONE
        })
        break;
      case 0:
        conflicts.push({
          type: ConflictType.RELEVANT_ARGUMENTS_UNSEEN,
          severity: ConflictSeverity.MINOR
        })
        break;
      default:
        conflicts.push({
          type: ConflictType.RELEVANT_ARGUMENTS_NOT_CHECKED,
          severity: ConflictSeverity.NONE
        })
        break;
    }
  }

  return (
    <TouchableHighlight
      style={{
        flex: 1,
        backgroundColor: Colors.quaternaryBG,
        elevation: 3,
        borderRadius: 15,
      }}
      onLongPress={drag}
      onPress={() => {
        navigation.push(selectScreenFromInfo(info), convertInfoToRouteParams(info, memberId));
      }}>
      <View flex style={{ backgroundColor: Colors.quaternaryBG, borderRadius: 15 }}>
        {showHeader ?
          <ContainerHeader
            container={statement}
            navigation={navigation}
            setShowMenu={setShowMenu}
            stance={stance}
            inGroup={info.loc === PathConstants.GROUP}
            groupId={groupId}
          />
          : null
        }
        <View paddingL-10 row paddingR-10 style={{ paddingTop: showHeader ? 0 : 10 }}>
          <Text textPrimary content style={{ flex: 1, width: 0 }}>
            {statement.getContent()}
            {checkForConflicts().length !== 1 && conflicts[0].severity !== ConflictSeverity.NONE ?
              <View marginL-3>
                <ConflictBadge
                  containerType={ContainerType.STATEMENT}
                  conflicts={conflicts.length === 0 ? checkForConflicts() : conflicts}
                  showConflictModalExternal={showConflictModal}
                  setShowConflictModalExternal={setShowConflictModal}
                />
              </View>
              : null
            }
          </Text>
        </View>
        <View>
          <ContainerFooter
            container={statement}
            containerInfo={info}
            parentInfo={parentInfo}
            navigation={navigation}
            conflicts={conflicts.length === 0 ? checkForConflicts() : conflicts}
            publishModalShown={showPublishModal}
            showPublishModal={() => setShowPublishModal(true)}
            hidePublishModal={() => setShowPublishModal(false)}
            memberId={memberId}
          />
        </View>
        {/* TODO: Unify action sheet with all other containers.*/}
        <ActionSheet
          visible={showMenu}
          dialogStyle={{
            borderTopLeftRadius: 30,
            borderTopRightRadius: 30,
            backgroundColor: Colors.quaternaryBG,
          }}
          options={actions}
          onDismiss={() => setShowMenu(false)}
        />
        <PublishModal
          show={showPublishModal}
          handleClose={() =>
            setShowPublishModal(false)
          }
          containerType={ContainerType.STATEMENT}
          conflicts={conflicts.length === 0 ? checkForConflicts() : conflicts}
          statementInfo={info}
          parentInfo={parentInfo}
          navigation={navigation}
        />
        <ConflictModal
          show={showConflictModal}
          handleClose={() => setShowConflictModal(false)}
          containerType={ContainerType.STATEMENT}
          conflicts={conflicts}
        />
      </View>
    </TouchableHighlight>
  );
}

function PremisesCard({
  info,
  parentInfo,
  navigation,
  drag,
  stance,
  groupId,
  memberId,
  groupAdminLoggedIn
}: containerProps): JSX.Element {
  const isMaintainer = useContext(IsMaintainerContext);
  const { argument, loading, error } = useArgumentListener(info);
  const strings = useContext(LocalizedStringsContext);
  const [showMenu, setShowMenu] = useState(false);
  const [user, userLoading] = useUserState();

  if (error) {
    return <ErrorContainer error={error} />;
  } else if (loading) {
    return <ContainerSkeleton />;
  }

  // Handle listener tries to load deleted argument.
  if (argument && argument.getData() === undefined) {
    return <></>;
  }

  const statementList: JSX.Element[] = [];
  argument.getChildInfos().forEach((childInfo, index) => {
    if ('groupId' in parentInfo) {
      childInfo = {
        loc: PathConstants.GROUP,
        id: childInfo.id,
        type: ContainerType.STATEMENT,
        groupId: parentInfo.groupId
      };
    }
    const wrapper = (child: JSX.Element) => {
      return index === 0 ? (
        <View key={index} marginH-10>
          {child}
        </View>
      ) : (
        <View key={index} marginH-10 marginT-10>
          {child}
        </View>
      );
    };
    statementList.push(
      wrapper(
        <Container
          key={childInfo.id}
          info={childInfo}
          parentInfo={info}
          navigation={navigation}
          showHeader={false}
          groupId={groupId}
          memberId={memberId}
          groupAdminLoggedIn={groupAdminLoggedIn}
        />,
      ),
    );
  });

  const actionOptions = [
    ...argument.isPersonal() || isMaintainer || groupAdminLoggedIn || argument.getUserId() === memberId ? [
      {
        label: strings.edit,
        onPress: () => {
          user.isAnonymous && !memberId ?
            LogInForFeatureAlert() :
            navigation.push('EditDialog', {
              ...info,
              statementGroup: argument,
              parentLoc: parentInfo.loc,
              parentType: parentInfo.type,
              parentId: parentInfo.id,
              groupId: groupId,
              memberId: memberId
            });
        },
      },
      {
        label: strings.delete,
        style: { backgroundColor: 'red' },
        onPress: () => {
          showDeleteDialog(
            () => argument.removeFromParent(parentInfo),
            strings,
          );
        },
      }
    ] : [],
    ...argument.isPublic() ? [
      {
        label: strings.copy_link,
        onPress: () => {
          Clipboard.setStringAsync(containerToLink(argument));
        },
      },
      {
        label: strings.share,
        onPress: () => {
          Share.share({
            message: containerToLink(argument),
          });
        },
      },
      {
        label: strings.report,
        onPress: () => {
          NotYetImplementedDialog('Reporting', strings);
        },
      }
    ] : [],
  ];

  // TODO: Unify all ActionSheets into one.
  return (
    <PremisesCardBorder stance={stance} drag={drag} info={info} navigation={navigation} memberId={memberId}>
      <View>
        <ContainerHeader
          container={argument}
          navigation={navigation}
          setShowMenu={setShowMenu}
          stance={stance}
          showStanceIndicator
          inGroup={info.loc === PathConstants.GROUP}
          groupId={groupId}
        />
        {statementList.length > 0 ? (
          statementList
        ) : (
          <View margin-10 flexG center row />
        )}
        <View>
          <ContainerFooter container={argument} memberId={memberId} />
        </View>
        <ActionSheet
          visible={showMenu}
          dialogStyle={{
            borderTopLeftRadius: 30,
            borderTopRightRadius: 30,
            backgroundColor: Colors.quaternaryBG,
          }}
          options={actionOptions}
          onDismiss={() => setShowMenu(false)}
        />
      </View>
    </PremisesCardBorder>
  );
}

function Source({
  info,
  parentInfo,
  navigation,
  drag,
  stance,
  showStanceIndicator = true,
  groupId,
  memberId,
  groupAdminLoggedIn
}: containerProps): JSX.Element {
  const isMaintainer = useContext(IsMaintainerContext);
  const { source, loading, error } = useSourceListener(info);
  const strings = useContext(LocalizedStringsContext);
  const [user, userLoading] = useUserState();
  const [showMenu, setShowMenu] = useState(false);

  if (error) {
    return <ErrorContainer error={error} />;
  } else if (loading) {
    return <ContainerSkeleton />;
  }

  // Handle listener tries to load deleted source.
  if (source && source.getData() === undefined) {
    return <></>;
  }

  let conflicts = [];

  const checkForConflicts = () => {
    checkSourceValidity();
    return conflicts;
  }

  const checkSourceValidity = () => {
    const sourceValid: any = -1;
    sourceValid === true ?
      conflicts.push({
        type: ConflictType.SOURCE_VALID,
        severity: ConflictSeverity.NONE
      })
      :
      sourceValid === false ?
        conflicts.push({
          type: ConflictType.SOURCE_NOT_VALID,
          severity: ConflictSeverity.MAJOR
        })
        :
        conflicts.push({
          type: ConflictType.SOURCE_NOT_CHECKED,
          severity: ConflictSeverity.MINOR
        });
  }

  const actionOptions = [
    ...source.isPersonal() || isMaintainer || groupAdminLoggedIn || source.getUserId() === memberId ? [
      {
        label: strings.edit,
        onPress: () => {
          user.isAnonymous && !memberId ?
            LogInForFeatureAlert() :
            navigation.push('EditDialog', {
              ...info,
              source: source,
              parentLoc: parentInfo.loc,
              parentType: parentInfo.type,
              parentId: parentInfo.id,
              groupId: groupId,
              memberId: memberId
            });
        },
      },
      {
        label: strings.delete,
        style: { backgroundColor: 'red' },
        onPress: () => {
          showDeleteDialog(
            () => source.removeFromParent(parentInfo),
            strings,
          );
        },
      }
    ] : [],
    ...source.isPublic() ? [
      {
        label: strings.report,
        onPress: () => {
          NotYetImplementedDialog('Reporting', strings);
        },
      }
    ] : [],
  ];

  return (
    <TouchableHighlight
      style={{
        backgroundColor: Colors.tertiaryBG,
        elevation: 3,
        borderRadius: 20,
        flexGrow: 1,
        marginLeft: 5
      }}
      onLongPress={drag}
      onPress={() => {
        navigation.push(selectScreenFromInfo(info), convertInfoToRouteParams(info, memberId));
      }}>
      <View flex style={{ backgroundColor: Colors.quaternaryBG, borderRadius: 15, padding: 2 }}>
        <ContainerHeader
          container={source}
          navigation={navigation}
          setShowMenu={setShowMenu}
          showStanceIndicator={showStanceIndicator}
          stance={stance}
          inGroup={info.loc === PathConstants.GROUP}
          groupId={groupId}
        />
        <View row paddingH-10>
          <Text flex content style={{ width: 0 }}>
            {source.getContent() ? "\"" + source.getContent() + "\"" : "" +
              (source.getReference().length > 0
                ? ' (' + source.getReference() + ')'
                : '')}
          </Text>
        </View>
        <View row padding-10 paddingT-0>
          <Text
            padding-5
            paddingL-0
            marginT-5
            flex
            style={{
              color: 'blue',
              textDecorationLine: 'underline',
              overflow: 'hidden',
              width: 0,
            }}
            numberOfLines={1}
            onPress={() => showLinkDialog(source.getUrl(), strings)}>
            {source.getUrl()}
          </Text>
        </View>
        <View>
          <ContainerFooter container={source} conflicts={conflicts.length === 0 ? checkForConflicts() : conflicts} memberId={memberId} />
        </View>
        <ActionSheet
          visible={showMenu}
          dialogStyle={{
            borderTopLeftRadius: 30,
            borderTopRightRadius: 30,
            backgroundColor: Colors.quaternaryBG,
          }}
          options={actionOptions}
          onDismiss={() => setShowMenu(false)}
        />
      </View>
    </TouchableHighlight>
  );
}

export function CategorySkeleton(): JSX.Element {
  return (
    <View
      style={{
        backgroundColor: Colors.quaternaryBG,
        elevation: 3,
        borderRadius: 15,
        height: 30,
      }}>
      <View paddingL-10 paddingV-5 paddingR-30>
        <SkeletonView spread height={20} width={40} borderRadius={10} />
      </View>
    </View>
  );
}

export function Category({
  info,
  parentInfo,
  navigation,
  drag,
  active = true,
  showOptions = false
}: containerProps): JSX.Element {
  const { category, loading, error } = useCategoryListener(info);
  const strings = useContext(LocalizedStringsContext);
  const [showMenu, setShowMenu] = useState(false);

  if (error) {
    return <ErrorContainer error={error} />;
  } else if (loading) {
    return <CategorySkeleton />;
  }

  // Handle listener tries to load deleted topic.
  if (category && category.getData() === undefined) {
    return <></>;
  }

  return (
    <TouchableHighlight
      style={[
        {
          elevation: 3,
        },
        active ? styles.borderPrimary : styles.borderPrimary,
        active ? styles.backgroundAccentPrimary : styles.backgroundTertiary,
      ]}
      onLongPress={drag}
      onPress={() => {
        navigation.push(selectScreenFromInfo(info), convertInfoToRouteParams(info));
      }}>
      <View padding-2 paddingR-5>
        <View row flexS paddingL-10 centerV style={{ paddingRight: showOptions ? 2 : 10 }}>
          <View flex row centerV>
            <Text h5 marginV-5 style={active ? styles.textAccentSecondary : { color: Colors.textSecondary }}>
              {category.getName()}
            </Text>
          </View>
          {showOptions ?
            <TouchableOpacity onPress={() => setShowMenu(true)}>
              <MaterialCommunityIcons name="dots-vertical" size={20} color={active ? Colors.textAccentSecondary : Colors.iconPrimary} />
            </TouchableOpacity>
            : null}
        </View>
        <ActionSheet
          visible={showMenu}
          dialogStyle={{
            borderTopLeftRadius: 30,
            borderTopRightRadius: 30,
            backgroundColor: Colors.quaternaryBG,
          }}
          options={[
            {
              label: strings.edit,
              onPress: () => {
                navigation.push('EditDialog', {
                  ...info,
                  category: category,
                  parentLoc: parentInfo ? parentInfo.loc : undefined,
                  parentType: parentInfo ? parentInfo.type : undefined,
                  parentId: parentInfo ? parentInfo.id : undefined,
                });
              },
            },
            {
              label: strings.delete,
              style: { backgroundColor: 'red' },
              onPress: () => {
                showDeleteDialog(
                  () => {
                    category.removeFromParent(parentInfo).then(
                      () => category.delete(),
                      (error) => console.log(error)
                    );
                  },
                  strings,
                );
              },
            },
          ]}
          onDismiss={() => setShowMenu(false)}
        />
      </View>
    </TouchableHighlight>
  );
}

type ContainerHeaderProps = {
  container: ContainerData;
  navigation: any;
  setShowMenu: (show: boolean) => void;
  stance?: StanceType;
  showStanceIndicator?: boolean;
  showUser?: boolean;
  showTimestamp?: boolean;
  inGroup?: boolean;
  groupId?: string;
};

function ContainerHeader({ container, navigation, setShowMenu, stance, showStanceIndicator = false, showUser = true, showTimestamp = true, inGroup = false, groupId }: ContainerHeaderProps): JSX.Element {
  const strings = useContext(LocalizedStringsContext);
  const isMaintainer = useContext(IsMaintainerContext);
  const [userState, loading] = useUserState();


  return (
    <View row spread paddingL-10 paddingV-5>
      <View flex row centerV>
        {showStanceIndicator ? <StanceIndicator stance={stance} /> : null}
        <Text textSecondary small bold>
          {container.getTypeAsLocalizedString(strings)}
        </Text>
        {userState && showUser ?
          <>
            <Text small textSecondary>{' ' + strings.added_by + ' '}</Text>
            <UserName
              userId={container.getUserId()}
              navigation={navigation}
              anonymizeUser={inGroup || !userState?.isAnonymous ? false : true}
              inGroup={inGroup}
              groupId={groupId}
            />
          </>
          : null
        }
        {userState && showTimestamp ?
          <Timestamp timestamp={container.getCreationTime()?.toDate()} />
          : null
        }
      </View>
      {userState ?
        <TouchableOpacity paddingR-5 onPress={() => setShowMenu(true)}>
          <MoreIcon height={20} width={20} fill={Colors.iconPrimary} />
        </TouchableOpacity>
        : null
      }
    </View>
  );
}

type ContainerFooterProps = {
  container: ContainerData;
  containerInfo?: ElementPath,
  parentInfo?: ElementPath,
  navigation?: any,
  publishModalShown?: boolean,
  conflicts?: any,
  showPublishModal?: () => void,
  hidePublishModal?: () => void,
  memberId?: string,
};

function ContainerFooter(
  {
    container,
    containerInfo,
    parentInfo,
    navigation,
    publishModalShown,
    conflicts,
    showPublishModal,
    hidePublishModal,
    memberId
  }: ContainerFooterProps
): JSX.Element {
  const strings = useContext(LocalizedStringsContext);
  const numberOfArguments = container.getChildPaths().length
  const [showPublishModalLocal, setShowPublishModal] = useState(publishModalShown ? publishModalShown : false);
  const [userState, loading] = useUserState();

  const size = 16;
  return (
    <View flex row right marginL-10 style={{ justifyContent: 'space-between' }}>
      <View marginV-10 style={{ alignItems: "center" }}>
      </View>
      <View flexS row centerV margin-5>
        {container.getType() !== ContainerType.STATEMENT && container.getType() !== ContainerType.ARGUMENT ?
          <ConflictBadge
            containerType={container.getType()}
            conflicts={conflicts === undefined ? [] : conflicts}
          />
          : null
        }
        {container.getType() !== ContainerType.ARGUMENT ?
          <View row centerV marginH-5>
            <MaterialIcons name="wrap-text" size={size * 1.125} color={Colors.iconSecondary} />
            <Text small iconSecondary marginL-1>
              {numberOfArguments}
            </Text>
            <Text small iconSecondary marginL-3>
              {numberOfArguments === 1 ? strings.argument : strings.arguments}
            </Text>
          </View>
          : null
        }
        <PublishModal
          show={showPublishModalLocal}
          handleClose={() =>
            hidePublishModal ? hidePublishModal() : setShowPublishModal(false)
          }
          containerType={container.getType()}
          conflicts={conflicts}
          statementInfo={containerInfo}
          parentInfo={parentInfo}
          navigation={navigation}
        />
        {container.isPersonal() && container.getType() === ContainerType.STATEMENT ?
          <View paddingH-5>
            <Pressable onPress={() => showPublishModal ? showPublishModal() : setShowPublishModal(true)}>
              <View row centerV>
                <MaterialIcons name="ios-share" size={size} color={Colors.iconSecondary} />
                <Text small iconSecondary marginL-2>
                  {strings.publish}
                </Text>
              </View>
            </Pressable>
          </View>
          : null
        }
        {container.isPublic() && (
          <TouchableOpacity
            paddingH-5
            onPress={() => {
              toggleBookmark(container.getInfo()).then(response => {
                if (response.add) {
                  // Toast.show(strings.added_to_bookmarks, Toast.SHORT);
                } else if (response.remove) {
                  // Toast.show(strings.removed_from_bookmarks, Toast.SHORT);
                } else {
                  // Toast.show(strings.error, Toast.LONG);
                }
              });
              NotYetImplementedDialog('Bookmarking', strings);
            }}>
            <MaterialIcons name="bookmark-border" size={size * 1.125} color={Colors.iconSecondary} />
          </TouchableOpacity>
        )}
        <TouchableOpacity
          paddingH-5
          centerV
          row
          onPress={() => {
            if (userState.isAnonymous && !memberId) {
              LogInForFeatureAlert()
            } else {
              const vote =
                container.getOwnVote(memberId) !== VoteType.UPVOTE
                  ? VoteType.UPVOTE
                  : VoteType.NOVOTE;
              container.setOwnVote(vote, memberId);
            }
          }}>
          {userState && container.getOwnVote(memberId) === VoteType.UPVOTE ?
            <Ionicons
              name={"md-thumbs-up-sharp"}
              size={size}
              color={Colors.iconAccent}
            />
            :
            <Feather
              name="thumbs-up"
              size={size}
              color={Colors.iconPrimary}
            />
          }
          {userState && (container.isPublic() || container.isInGroup()) ?
            <Text
              bold
              small
              style={{
                color:
                  container.getOwnVote(memberId) === VoteType.UPVOTE
                    ? Colors.iconPrimary
                    : Colors.iconPrimary,
                marginLeft: 3
              }}>
              {container.getUpvotes()}
            </Text>
            : null
          }
        </TouchableOpacity>
        <TouchableOpacity
          paddingH-5
          centerV
          row
          onPress={() => {
            if (userState.isAnonymous && !memberId) {
              LogInForFeatureAlert()
            } else {
              const vote =
                container.getOwnVote(memberId) !== VoteType.DOWNVOTE
                  ? VoteType.DOWNVOTE
                  : VoteType.NOVOTE;
              container.setOwnVote(vote, memberId);
            }
          }}>
          {userState && container.getOwnVote(memberId) === VoteType.DOWNVOTE ?
            <Ionicons
              name={container.getOwnVote(memberId) === VoteType.DOWNVOTE ? "md-thumbs-down-sharp" : 'md-thumbs-down-sharp'}
              size={size}
              color={container.getOwnVote(memberId) === VoteType.DOWNVOTE ? Colors.iconAccent : Colors.iconPrimary}
            />
            :
            <Feather
              name="thumbs-down"
              size={size}
              color={container.getOwnVote(memberId) === VoteType.DOWNVOTE ? Colors.iconAccent : Colors.iconPrimary}
            />
          }
          {userState && (container.isPublic() || container.isInGroup()) ?
            <Text
              bold
              small
              style={{
                color:
                  container.getOwnVote(memberId) === VoteType.UPVOTE
                    ? Colors.iconPrimary
                    : Colors.iconPrimary,
                marginLeft: 3
              }}>
              {container.getDownvotes()}
            </Text>
            : null
          }
        </TouchableOpacity>
      </View>
    </View>
  );
}

type stanceIndicatorProps = {
  stance?: StanceType;
};

function StanceIndicator(props: stanceIndicatorProps): JSX.Element {
  const isMobile = useIsMobile();
  const stance = props.stance;
  const color = stance === StanceType.SUPPORT ? Colors.greenBG : Colors.redBG;
  const size = 15;
  const thickness = 3;

  return (
    <View style={{ marginLeft: -size, marginRight: 3 }}>
      <LinearGradient
        colors={[isMobile ? Colors.iconSecondary : color, color]}
        locations={[0, 0.5]}
        start={{ x: 0.0, y: 1.0 }}
        end={{ x: 1.0, y: 1.0 }}
        style={{ borderRadius: THREAD_WIDTH / 2 }}
      >
        <View
          style={{
            width: size,
            height: THREAD_WIDTH,
          }}
        />
      </LinearGradient>
    </View>

  );
}

type ErrorContainerProps = {
  error: Error;
};

function ErrorContainer({ error }: ErrorContainerProps): JSX.Element {
  const strings = useContext(LocalizedStringsContext);

  return (
    <TouchableHighlight
      style={{
        backgroundColor: Colors.quaternaryBG,
        elevation: 3,
        borderRadius: 15,
      }}
      onPress={undefined}>
      <View style={{ backgroundColor: Colors.quaternaryBG, borderRadius: 15 }}>
        <View row spread paddingL-10>
          <View flex row centerV>
            <Text small bold>
              {strings.error}
            </Text>
          </View>
        </View>
        <View row paddingL-10 paddingR-10>
          <Text flex content>
            {error.message}
          </Text>
        </View>
        <View padding-50></View>
      </View>
    </TouchableHighlight>
  );
}

function ConditionCardForStatement({
  info,
  parentInfo,
  navigation,
  drag,
  stance,
  groupId,
  memberId,
}: containerProps): JSX.Element {
  const isMaintainer = useContext(IsMaintainerContext);
  // const {statementGroup, loading, error} = useStatementGroupListener(info);
  const { statement, loading, error } = useStatementListener(info);
  const strings = useContext(LocalizedStringsContext);
  const statementList: JSX.Element[] = [];
  const [showMenu, setShowMenu] = useState(false);
  const [user, userLoading] = useUserState();

  if (error) {
    return <ErrorContainer error={error} />;
  } else if (loading) {
    return <ContainerSkeleton />;
  }

  // TODO: Unify all ActionSheets into one.
  return (
    <PremisesCardBorder stance={stance} drag={drag} info={info} navigation={navigation}>
      <View>
        <View row spread paddingL-10 paddingV-5>
          <View flex row centerV>
            <View flex row centerV>
              <StanceIndicator stance={stance} />
              <Text textSecondary small bold>
                {strings.statement_group}
              </Text>
              <Text textSecondary small>{' ' + strings.added_by + ' '}</Text>
              <UserName
                userId={statement.getUserId()}
                navigation={navigation}
              />
              <Timestamp timestamp={statement.getCreationTime().toDate()} />
            </View>
          </View>
          {/* TODO: Unify this to be able to use a common Header component. */}
          {(isMaintainer ||
            (statement.isOwnedByCurrentUser() &&
              statement.isPersonal())) && (
              <TouchableOpacity paddingR-5 onPress={() => setShowMenu(true)}>
                <MoreIcon height={20} width={20} fill={Colors.iconPrimary} />
              </TouchableOpacity>
            )}
        </View>
        <View key='0' marginH-10>
          <Container
            key={info.id}
            info={info}
            parentInfo={parentInfo}
            navigation={navigation}
          />
        </View>
        <View>
          <ContainerFooter container={statement} memberId={memberId} />
        </View>
        <ActionSheet
          visible={showMenu}
          dialogStyle={{
            borderTopLeftRadius: 30,
            borderTopRightRadius: 30,
            backgroundColor: Colors.quaternaryBG,
          }}
          options={[
            {
              label: strings.copy_link,
              onPress: () => {
                Clipboard.setStringAsync(containerToLink(statement));
              },
            },
            {
              label: strings.share,
              onPress: () => {
                Share.share({
                  message: containerToLink(statement),
                });
              },
            },
            {
              label: strings.report,
              onPress: () => {
                NotYetImplementedDialog('Reporting', strings);
              },
            },
            ...(!user.isAnonymous && statement && (statement.isPersonal() || isMaintainer) ?
              [
                {
                  label: strings.edit,
                  onPress: () => {
                    navigation.push('EditDialog', {
                      ...info,
                      statement: statement,
                      parentLoc: parentInfo.loc,
                      parentType: parentInfo.type,
                      parentId: parentInfo.id,
                      groupId: groupId,
                      memberId: memberId
                    });
                  },
                },
                {
                  label: strings.delete,
                  style: { backgroundColor: 'red' },
                  onPress: () => {
                    showDeleteDialog(
                      () => statement.removeFromParent(parentInfo),
                      strings,
                    );
                  },
                },
              ]
              :
              []
            )
          ]}
          onDismiss={() => setShowMenu(false)}
        />
      </View>
    </PremisesCardBorder>
  );
}

type PremisesCardBorderProps = {
  stance: StanceType;
  drag: any;
  navigation?: any;
  info?: any;
  lastLine?: boolean;
  isActive?: boolean;
  children?: JSX.Element;
  arrowBorder?: boolean;
  memberId?: string;
};

export function PremisesCardBorder({ stance, drag, navigation, info, lastLine, isActive, children, arrowBorder = false, memberId }: PremisesCardBorderProps): JSX.Element {
  return (
    <TouchableHighlight
      style={{
        backgroundColor: Colors.secondaryBG,
        elevation: 3,
        borderRadius: 20,
        flexGrow: 1,
        marginLeft: 5
      }}
      onLongPress={drag}
      onPress={() => {
        if (navigation && info) {
          navigation.push(selectScreenFromInfo(info), { ...info, member: memberId });
        }
      }}>
      <LinearGradient
        locations={[0, 0.5]}
        colors={[
          !arrowBorder ? Colors.secondaryBG :
            stance === StanceType.SUPPORT ? Colors.greenBG :
              stance === StanceType.OPPOSE ? Colors.redBG : Colors.textSecondary,
          Colors.tertiaryBG]}
        // colors={[Colors.secondaryBG, Colors.tertiaryBG]}
        start={{ x: 0.0, y: 1.0 }}
        end={{ x: 1.0, y: 1.0 }}
        style={{ borderRadius: 19, borderTopLeftRadius: 0 }}
      >
        <View style={{ marginLeft: THREAD_WIDTH, marginBottom: THREAD_WIDTH }}>
          <LinearGradient
            locations={[0, 0.2]}
            // colors={[Colors.secondaryBG, Colors.tertiaryBG]}
            colors={[Colors.secondaryBG, Colors.tertiaryBG]}
            start={{ x: 0.0, y: 0.0 }}
            end={{ x: 1.0, y: 1.0 }}
            style={{ borderRadius: 19, borderTopLeftRadius: 0 }}
          >
            <View style={{ marginRight: THREAD_WIDTH, marginTop: THREAD_WIDTH }}>
              <View
                style={{
                  backgroundColor: Colors.secondaryBG,
                  borderRadius: 16,
                  borderTopLeftRadius: 0,
                  flexGrow: 1,
                }}>
                {children}
              </View>
            </View>
          </LinearGradient>
        </View>
      </LinearGradient>
    </TouchableHighlight>
  );
}

export function ArgumentCard(
  {
    navigation,
    info,
    argument: argument,
    groupId,
    memberId,
    groupAdminLoggedIn
  }:
    {
      navigation: any,
      info: ElementPath,
      argument: ContainerData,
      groupId?: string,
      memberId?: string,
      groupAdminLoggedIn?: boolean
    }): JSX.Element {
  const { statement } = argument ? useStatementListener(argument.getParentInfos()[0]) : undefined;
  const [stance, setStance] = useState<StanceType>(StanceType.NEUTRAL);
  const [showMenu, setShowMenu] = useState(false);
  const parentInfo = argument ? argument.getParentInfos()[0] : undefined;
  const isMaintainer = useContext(IsMaintainerContext);
  const [user, userLoading] = useUserState();

  useEffect(() => {
    if (argument && statement && statement.getChildStances().length > 0) {
      setStance(statement.getChildStance(argument.getPath()));
    }
  }, [statement])

  const actionOptions = [
    ...argument.isPersonal() || isMaintainer || groupAdminLoggedIn || argument.getUserId() === memberId ? [
      {
        label: strings.edit,
        onPress: () => {
          user.isAnonymous && !memberId ?
            LogInForFeatureAlert() :
            navigation.push('EditDialog', {
              ...info,
              statementGroup: argument,
              parentLoc: parentInfo.loc,
              parentType: parentInfo.type,
              parentId: parentInfo.id,
              groupId: groupId,
              memberId: memberId
            });
        },
      },
      {
        label: strings.delete,
        style: { backgroundColor: 'red' },
        onPress: () => {
          showDeleteDialog(
            () => argument.removeFromParent(parentInfo),
            strings,
          );
        },
      }
    ] : [],
    ...argument.isPublic() ? [
      {
        label: strings.copy_link,
        onPress: () => {
          Clipboard.setStringAsync(containerToLink(argument));
        },
      },
      {
        label: strings.share,
        onPress: () => {
          Share.share({
            message: containerToLink(argument),
          });
        },
      },
      {
        label: strings.report,
        onPress: () => {
          NotYetImplementedDialog('Reporting', strings);
        },
      }
    ] : [],
  ];

  return (
    <View bg-secondaryBG>
      <View>
        {argument ?
          <ContainerHeader
            container={argument}
            navigation={navigation}
            setShowMenu={setShowMenu}
            stance={stance}
            inGroup={info.loc === PathConstants.GROUP}
            groupId={groupId}
          />
          : null}
      </View>
      <View paddingH-10>
        <View>
          {argument.getParentInfos().length > 0 ?
            <Statement
              info={argument.getParentInfos()[0]}
              parentInfo={argument.getParentInfos()[0]}
              navigation={navigation}
              showHeader={false}
              groupId={groupId}
              memberId={memberId}
              groupAdminLoggedIn={groupAdminLoggedIn}
            /> : null}
        </View>
        <View>
          <ImplicationIndicator stance={stance} />
        </View>
        <View marginL-6>
          <PremisesCardBorder drag={undefined} arrowBorder={true} stance={stance} memberId={memberId}>
            <View flex padding-10>
              <FlatList
                data={argument.getChildInfos()}
                renderItem={(itemInfo: ListRenderItemInfo<any>): React.ReactElement<any, string | React.JSXElementConstructor<any>> =>
                  <View>
                    <Statement
                      key={itemInfo.item.id}
                      info={itemInfo.item}
                      parentInfo={info}
                      navigation={navigation}
                      showHeader={false}
                      groupId={groupId}
                      memberId={memberId}
                      groupAdminLoggedIn={groupAdminLoggedIn}
                    />
                  </View>
                }
                ItemSeparatorComponent={() => <View style={{ height: 10 }} />}
              />
              <ContainerFooter container={argument} memberId={memberId} />
            </View>
          </PremisesCardBorder>
        </View>
      </View>
      <ActionSheet
        visible={showMenu}
        dialogStyle={{
          borderTopLeftRadius: 30,
          borderTopRightRadius: 30,
          backgroundColor: Colors.quaternaryBG,
        }}
        options={actionOptions}
        onDismiss={() => setShowMenu(false)}
      />
    </View>
  );
}
