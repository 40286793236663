import { FlatList, Pressable, StatusBar } from 'react-native';
import { Colors, Dialog, Modal, PanningProvider, Text, View } from 'react-native-ui-lib';
import { ContainerType, navigationProps } from '../typings/types';
import LogoIcon from '../../assets/res/LogoLM.svg';
import React, { useState } from 'react';
import { Ionicons } from '@expo/vector-icons';
import { strings } from '../localization/localization.web';
import { useIsMobile } from '../MediaQueries';
import { MaterialIcons } from '@expo/vector-icons';
import { NotYetImplementedDialog } from './Dialogs';
import { LogInButton } from './Buttons';
import { getAuth } from 'firebase/auth';

/**
 * Set the options for displaying the header.
 *
 * @param navigationProps Not yet used. Must be set if profile icon shall be shown and navigatable.
 * @returns Options for displaying the header.
 */
export function Header(
  navigationProps: navigationProps | undefined = undefined,
): {} {
  const user = getAuth().currentUser;
  // Set status bar to same style as header.
  StatusBar.setBarStyle('dark-content');
  StatusBar.setBackgroundColor(Colors.primaryBG);
  // StatusBar.setTranslucent(true);

  return {
    headerMode: 'float',
    title: 'LucidMind',
    headerTitle: () => {
      return <LogoIcon height={32} width={32} />;
    },
    headerTitleAlign: 'center',
    headerStyle: {
      backgroundColor: Colors.primaryBG,
      borderBottomWidth: 0,
    },
    headerRight: () =>
      <View row centerV marginR-20>
        <Pressable onPress={() => NotYetImplementedDialog(strings.search, strings)}>
          <MaterialIcons name="search" size={30} color={Colors.iconSecondary} />
        </Pressable>
        <View marginL-5>
          <HelpButton />
        </View>
        {user.isAnonymous ?
          <View marginL-5>
            <LogInButton onPress={() => navigationProps.navigation.navigate('login')} />
          </View> : null
        }
      </View>
    // headerRight: () => {
    //     if(navigationProps && navigationProps.navigation) {
    //         let content = <ProfileIcon height={30} width={30} style={{color: 'white', margin: 5}}/>
    //         if(user) {
    //             if(user.photoURL) {
    //                 content = <Card.Image height={30} width={30} style={{borderRadius: 30, margin: 5}} source={{uri: user.photoURL}}/>
    //             } else {
    //                 if(user.displayName) {
    //                     var lettersToDisplay = "";
    //                     if(user.displayName.split(" ", 2).length > 1) {
    //                         lettersToDisplay = user.displayName.split(" ")[0].substring(0, 1) + user.displayName.split(" ")[1].substring(0, 1);
    //                     } else {
    //                         lettersToDisplay = user.displayName.substring(0, 2);
    //                     }
    //                     content = <Text white margin-8 center text70 bold height={25} width={25}>{lettersToDisplay}</Text>
    //                 }
    //             }
    //         }
    //         return (
    //             <TouchableOpacity bg-logo height={45} width={45} style={{borderRadius: 30}} onPress={() => navigationProps.navigation.navigate('Profile')}>
    //                 {content}
    //             </TouchableOpacity>
    //         );
    //     } else {
    //         return null;
    //     }
    // },
  };
}

export function HelpButton({ initialContentId, inline }: { initialContentId?: String, inline?: boolean }): JSX.Element {

  const iconSize = inline ? 20 : 30;

  let locationType;
  switch (initialContentId) {
    case ContainerType.STATEMENT:
      locationType = 'statement';
      break;
    case ContainerType.ARGUMENT:
      locationType = 'argument';
      break;
    case ContainerType.SOURCE:
      locationType = 'source';
      break;
    case ContainerType.CATEGORY:
      locationType = 'topic';
      break;
  }

  const [isHelpVisible, setIsHelpVisible] = useState(false);
  const [activeContent, setActiveContent] = useState(locationType ? locationType : initialContentId);
  const isMobile = useIsMobile();
  const helpContent = [
    {
      id: 'statement',
      title: strings.help.statement.header,
      content: strings.help.statement.text,
      relatedContentIds: ['argument', 'premise', 'conclusion']
    },
    {
      id: 'argument',
      title: strings.help.argument.header,
      content: strings.help.argument.text,
      relatedContentIds: ['statement', 'premise', 'conclusion']
    },
    {
      id: 'premise',
      title: strings.help.premise.header,
      content: strings.help.premise.text,
      relatedContentIds: ['statement', 'argument', 'conclusion']
    },
    {
      id: 'conclusion',
      title: strings.help.conclusion.header,
      content: strings.help.conclusion.text,
      relatedContentIds: ['statement', 'argument', 'premise']
    },
    {
      id: 'topic',
      title: strings.help.topic.header,
      content: strings.help.topic.text,
      relatedContentIds: ['statement', 'argument']
    },
    {
      id: 'personal_area',
      title: strings.help.personal_area.header,
      content: strings.help.personal_area.text,
      relatedContentIds: ['public_area', 'publish']
    },
    {
      id: 'public_area',
      title: strings.help.public_area.header,
      content: strings.help.public_area.text,
      relatedContentIds: ['personal_area', 'publish']
    },
    {
      id: 'publish',
      title: strings.help.publish.header,
      content: strings.help.publish.text,
      relatedContentIds: ['personal_area', 'public_area']
    },
    {
      id: 'conflict',
      title: strings.help.conflict.header,
      content: strings.help.conflict.text,
      relatedContentIds: ['statement', 'argument', 'publish']
    },
    {
      id: 'source',
      title: strings.help.source.header,
      content: strings.help.source.text,
      relatedContentIds: ['statement', 'argument']
    },
  ];

  return (
    <>
      <Pressable onPress={() => setIsHelpVisible(true)}>
        <View>
          <Ionicons name="md-help" size={iconSize} color={Colors.iconSecondary} />
        </View>
      </Pressable>
      <Dialog
        visible={isHelpVisible}
        onDismiss={() => setIsHelpVisible(!isHelpVisible)}
        panDirection={PanningProvider.Directions.UP}
      >
        <View
          bg-primaryBG
          padding-25
          br60
        >
          <View marginB-2>
            {activeContent !== undefined ?
              <Pressable onPress={() => setActiveContent(undefined)}>
                <View row style={{ alignItems: 'flex-end' }}>
                  <Ionicons name="md-arrow-back" size={15} color={Colors.iconSecondary} />
                  <Text textSecondary>
                    {strings.all_topics}
                  </Text>
                </View>
              </Pressable>
              : null
            }
          </View>
          <View flex marginB-8 row style={{ alignItems: 'flex-end' }}>
            <View>
              <Text textPrimary text60>{activeContent === undefined ? strings.help.header : helpContent.filter((helpContent) => helpContent.id === activeContent)[0]?.title}</Text>
            </View>
          </View>
          <View>
            {activeContent !== undefined ? <>
              <Text textSecondary>
                {helpContent.filter((helpContent) => helpContent.id === activeContent)[0].content}
              </Text>
            </> : null}
          </View>
          <View>
            {activeContent === undefined ?
              <FlatList
                key={isMobile ? 'mobile_content_list' : 'desktop_content_list'}
                data={helpContent}
                renderItem={({ item }) =>
                  <View flex>
                    <Pressable onPress={() => setActiveContent(item.id)}>
                      <View style={{}}>
                        <Text underline textPrimary>{item.title}</Text>
                      </View>
                    </Pressable>
                  </View>
                }
                ItemSeparatorComponent={() => <View style={{ height: 3 }} />}
                keyExtractor={item => item.id}
                initialNumToRender={helpContent.length}
                numColumns={isMobile ? 1 : 2}
              />
              :
              <FlatList
                data={helpContent.filter((helpContent) => helpContent.id === activeContent)[0].relatedContentIds}
                renderItem={({ item }) =>
                  <Pressable onPress={() => setActiveContent(item)}>
                    <View style={{}}>
                      <Text underline textPrimary>{helpContent.filter((helpContent) => helpContent.id === item)[0].title}</Text>
                    </View>
                  </Pressable>
                }
                ItemSeparatorComponent={() => <View style={{ height: 3 }} />}
                ListHeaderComponent={() => <Text marginT-13 text70 textSecondary>{strings.related_topics}</Text>}
                keyExtractor={item => item}
                initialNumToRender={helpContent.length}
              />
            }
          </View>
        </View>
      </Dialog>
    </>
  )
}