import { Colors, ListItem, Text, View } from 'react-native-ui-lib';
import { FirestoreConstants, navigationProps } from '../typings/types';
import { FlatList } from 'react-native';
import React, { useEffect, useState } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { useIsMobile } from '../MediaQueries';
import { strings } from '../localization/localization.web';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { useUserState } from '../firebaseWrapper/firebaseWrapper';
import { FloatingAddGroupButton } from './FloatingActionButton';
import { MaterialIcons } from '@expo/vector-icons';
import { NothingHereInformation } from './Alert';

export function GroupsScreen({
  navigation,
  route,
}: navigationProps): JSX.Element {
  const [transitionFinished, setTransitionFinished] = useState(false);
  const isMobile = useIsMobile();
  const [user, loading] = useUserState();
  const [groups, setGroups] = React.useState([]);

  useFocusEffect(() => {
    if (!transitionFinished) {
      setTransitionFinished(true);
    }
  });

  useEffect(() => {
    const groupsReference = collection(getFirestore(), FirestoreConstants.GROUPS);
    const groupsQuery = query(groupsReference,
      where("admin", '==', user.uid),
    );
    getDocs(groupsQuery).then((querySnapshot) => {
      setGroups(
        querySnapshot.docs.map((document) => ({ ...document.data(), id: document.id }))
      )
    }
    );
  }, [user]);

  const navigateToGroup = (groupId: string) => {
    navigation.push("group", { loc: "groups", id: groupId, type: "group" });
  }

  return (
    <View
      flex
      style={{
        backgroundColor: Colors.primaryBG
      }}
    >
      <View flex
        style={{
          backgroundColor: isMobile ? 'transparent' : Colors.secondaryBG,
          borderRadius: 20,
          marginHorizontal: isMobile ? 0 : 5,
          marginVertical: 5,
          overflow: 'hidden',
          padding: 20
        }}
      >
        <Text marginB-10 text40 style={{ color: Colors.textPrimary }}>
          {strings.groups}
        </Text>
        <FlatList
          data={groups}
          showsVerticalScrollIndicator={false}
          numColumns={1}
          renderItem={({ item }) => (
            <ListItem height={45} onPress={() => navigateToGroup(item.id)} backgroundColor={Colors.tertiaryBG} style={{ borderRadius: 8 }}>
              <ListItem.Part left>
                <View style={{ flexDirection: 'row', alignItems: 'center', paddingLeft: 10 }}>
                  <MaterialIcons name="group" size={20} color={Colors.iconSecondary} />
                  <View paddingL-10 style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Text h3>{item.name}</Text>
                  </View>
                </View>
              </ListItem.Part>
            </ListItem>
          )}
          keyExtractor={item => item.id}
          ItemSeparatorComponent={() => <View style={{ height: 10 }} />}
          ListEmptyComponent={() => <NothingHereInformation text={strings.no_groups_info} />}
        />
      </View>
      <FloatingAddGroupButton navigation={navigation} />
    </View>
  );
}