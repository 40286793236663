import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useContext, useEffect, useState } from 'react';
import { SkeletonView, Text, TouchableOpacity } from 'react-native-ui-lib';
import { useUserName } from '../firebaseWrapper/firebaseWrapper';
import { LocalizedStringsContext } from '../localization/localization.native';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { FirestoreConstants } from '../typings/types';

type userNameProps = {
  userId: string;
  navigation?: any;
  anonymizeUser?: boolean;
  inGroup?: boolean;
  groupId?: string;
};

export function UserName({ userId, navigation, anonymizeUser = false, inGroup = false, groupId }: userNameProps): JSX.Element {
  const strings = useContext(LocalizedStringsContext);
  const [userName, loading] = useUserName(userId);
  const [memberName, setMemberName] = useState('');
  const [showUserName, setShowUserName] = useState(!anonymizeUser);

  useEffect(() => {
    if (inGroup) {
      getMemberName(userId);
    }
  }, [userId, loading]);

  const getMemberName = (userId: string) => {
    if (inGroup && !loading && !userName && groupId && userId) {
      const documentReference = doc(getFirestore(), FirestoreConstants.GROUPS, groupId, FirestoreConstants.MEMBERS, userId);
      getDoc(documentReference).then((documentSnapshot) => {
        if (documentSnapshot.exists()) {
          setMemberName(documentSnapshot.data().name);
        } else {
          console.log('No such document!');
        }
      }).catch((error) => {
        console.log('Error getting document:', error);
      });
    } else {
    }
  }

  // TODO: Remove this workaround and either use useNavigation() or require passing of navigation as prop.
  // const navigation = props.navigation;

  if (loading) {
    return <SkeletonView width={50} height={14} borderRadius={2} />;
  } else {
    return (
      <TouchableOpacity
        onPress={() => {
          if (userName === strings.unknown_user || navigation === undefined) {
            return;
          } else if (!showUserName) {
            setShowUserName(true);
          } else {
            navigation.navigate('profile', { userId: userId });
          }
        }}>
        <Text textSecondary small bold>
          {showUserName && userName ? userName :
            inGroup ? memberName
              : strings.anonymous}
        </Text>
      </TouchableOpacity>
    );
  }
}

dayjs.extend(relativeTime);

export function Timestamp(props: {
  timestamp: Date;
}): JSX.Element {
  return (
    <Text small textSecondary>
      {props.timestamp ? ' ' + dayjs(props.timestamp).fromNow() : ''}
    </Text>
  );
}
