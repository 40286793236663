export const de = {
  // Navigation
  public: 'Öffentlich', // Vote: 'Home', 'Feed', 'Öffentlich', 'Öffentlicher Bereich'
  private: 'Persönlich', // Vote: 'Dein Bereich', 'Privater Bereich', 'Privat', 'Deine Weltanschauung', 'Persönlich'
  profile: 'Profil',
  groups: 'Gruppen',
  settings: 'Einstellungen',
  // container types
  opinion: 'Meinung',
  fact: 'Fakt',
  statement: 'Aussage',
  statements: 'Aussagen',
  source: 'Quelle',
  sources: 'Quellen',
  argument: 'Argument',
  arguments: 'Argumente',
  statement_group: 'Argument',
  category: 'Thema',
  topics: 'Themen',
  none: 'Leer',

  // Introduction
  introduction: 'Einführung',
  introduction_statements:
    'Bei LucidMind kannst du Meinungen und Informationen einfach und logisch strukturiert sammeln und teilen.\nDas Grundelement ist die Aussage. Aussagen sind Sätze, die entweder wahr oder falsch sind. Du kannst Aussagen in Themen strukturieren, um die Übersicht zu behalten.',
  introduction_arguments:
    'Aussagen kannst du mit Argumenten be- oder widerlegen. Argumente bestehen aus einer oder mehreren Annahmen. Annahmen sind ebenfalls Aussagen. Du kannst außerdem auch Quellen für Aussagen hinterlegen.',
  introduction_private_public:
    'Es gibt bei LucidMind einen persönlichen und den öffentlichen Bereich.\nIm persönlichen Bereich kannst du deine eigene Meinung als Aussagen und Argumente strukturieren und hinzufügen.\nIm öffentlichen Bereich kannst du deine eigenen Aussagen teilen und Aussagen anderer finden. Zukünftig werden dir auch automatisch Aussagen und Argumente aus dem öffentlichen Bereich vorgeschlagen, um deine Meinung zu hinterfragen.',
  introduction_interaction:
    'Du kannst mit Aussagen und später auch Argumenten interagieren.\nEin Daumen nach oben bedeutet, dass du glaubst, dass diese Aussage wahr ist. Ein Daumen nach unten bedeutet, dass du glaubst, dass diese Aussage falsch ist.',
  introduction_checks_conflicts:
    'Aussagen und später auch Argumente werden bestimmten Kontrollen unterzogen, die dir helfen sollen, deine Argumentation möglichst schlüssig aufzubauen. Bei Konflikten in deiner Argumentation werden dir Lösungsvorschläge angezeigt.',
  introduction_test_version:
    'Dies ist eine Testversion. Manche Funktionen sind noch  nicht verfügbar, aber schon über verschiedene Knöpfe und Informationstafeln angeteasert.',
  next: 'Nächste',
  previous: 'Vorherige',
  continue: 'Weiter',

  // Container forms
  add_topic: 'Neues Thema',
  add_topic_name_hint: 'Gib die Bezeichnung des Themas an...',
  add_statement: 'Neue Aussage',
  add_statement_content_hint: 'Gib eine Aussage ein...',
  add_argument: 'Neues Argument',
  add_source: 'Neue Quelle',
  add_source_content_hint: 'Gib das Zitat ein...',
  add_source_url_hint: 'Gib die URL ein... (Beginnend mit "https://")',
  add_source_reference_hint:
    'Gib -falls zutreffend- eine Referenz an... (z.B."S.5, Z.34")',
  add_condition: 'Neue Annahme',
  add_condition_content_hint: 'Gib eine Annahme ein...',

  search: 'Suche...',
  search_teaser: 'Bald kannst du nach Themen und Aussagen suchen.',
  save: 'Speichern',
  create: 'Erstellen',
  cancel: 'Abbrechen',
  delete: 'Löschen',
  delete_question: 'Bist du dir sicher, dass du das Element löschen möchtest?',
  add: 'Hinzufügen',
  yes: 'Ja',
  okay: 'Okay',
  edit: 'Bearbeiten',
  move: 'Verschieben',
  copy: 'Kopieren',
  paste: 'Einfügen',
  share: 'Teilen',
  critisize: 'Kritisieren',
  report: 'Melden',
  back: 'Zurück',
  block: 'Blockieren',
  unblock: 'Entblockieren',
  mute: 'Stummschalten',
  unmute: 'Stummschaltung aufheben',
  copy_link: 'Link kopieren',
  copied_to_clipboard: 'In Zwischenablage kopiert',
  link: 'Externer Link',
  link_question: 'Bist du dir sicher, dass du den folgenden Link öffnen möchtest?',
  refresh: 'Neu laden',
  load_more: 'Mehr laden',
  nothing_here: 'Hier ist noch nichts...',
  nothing_here_funny_nietzsche: 'Nichts ist hier, alles ist erlaubt. (Zumindest fast alles)',
  nothing_here_funny_socrates: 'Ich weiß, dass Du nichts weißt. (Zumindest nichts hierüber)',
  publish: 'Veröffentlichen',
  published: 'Veröffentlicht',
  publish_statement: 'Aussage veröffentlichen',
  published_statements: 'Veröffentlichte Aussagen',
  published_statements_info:
    'Hier kannst du bald deine veröffentlichten Aussagen sehen.',
  bookmark: 'Lesezeichen',
  added_to_bookmarks: 'Zu Lesezeichen hinzugefügt',
  removed_from_bookmarks: 'Von Lesezeichen entfernt',
  bookmarks: 'Lesezeichen',
  bookmarks_info: 'Hier kannst du bald deine gemerkten Aussagen sehen.',
  bookmark_tooltip:
    'Hier kannst du bald Aussagen zu deinen Lesezeichen hinzufügen.',
  following: 'folge ich',
  followed: 'folgen mir',
  by: 'von',
  added_by: 'hinzugefügt von',
  anonymous: 'anonym',
  cancel_changes: 'Änderungen verwerfen',
  confirm_changes: 'Änderungen bestätigen',
  delete_account: 'Benutzerkonto löschen',
  danger_zone: 'Unwiderrufbar',
  account: 'Benutzerkonto',
  change_password: 'Passwort ändern',
  change_email: 'Mailadresse ändern',
  about: 'über',
  unknown_user: 'Unbekannter Nutzer',
  by_unknown_user: 'unbekanntem Nutzer',
  found_reasoning: 'Herleitung gefunden',
  no_reasoning_found: 'Keine Herleitung gefunden',
  loading: 'Laden...',
  content_placeholder: 'Füge hier deinen Inhalt ein',
  category_placeholder: 'Füge hier den Namen des Themas ein',
  url_placeholder: 'Füge hier die URL der Quelle ein',
  reference_placeholder: 'Füge hier die Referenz der Quelle ein',
  support: 'Dafür',
  oppose: 'Dagegen',
  error: 'Fehler',
  error_generate_argument: 'Fehler beim Generieren des Arguments',
  generating_argument: 'Generiere Argument...',
  not_yet_implemented:
    'An dieser Funtion arbeiten wir leider noch, tut uns leid!',
  interested_question:
    'Gerne kannst du uns wissen lassen, wenn diese Funktion für dich interessant ist.',
  interested: 'Interessiert',
  not_interested: 'Nicht interessiert',
  personal_categories_header: 'Deine Themen',
  personal_statements_header: 'Deine Aussagen',
  feed_header: 'Neuigkeiten',
  no_topics_info:
    'Keine Themen vorhanden. Klicke "+", um ein Thema hinzuzufügen.',
  no_arguments_info:
    'Keine Argumente vorhanden. Klicke "+", um ein Argument hinzuzufügen.',
  no_statements_info:
    'Keine Aussagen vorhanden. Klicke "+", um eine Aussage hinzuzufügen.',
  no_groups_info: 'Keine Gruppen vorhanden. Klicke "+", um eine Gruppe zu erstellen oder frage deinen Gruppenadministrator nach dem Gruppenlink.',
  propose_arguments: 'Argument vorschlagen',
  propose_arguments_tooltip:
    'Bald kannst Du Dir hier Argumente zu dieser Aussage vorschlagen lassen. Die Argumente werden im öffentlichen Bereich gesucht oder automatisch generiert.',
  tree_view_info:
    'Betrachte ein Thema in der übersichtlichen Baumansicht. Funktion noch nicht implementiert',
  feed_info:
    'Der öffentliche Feed ist bisher nur testweise implementiert, um die Grundfunktionen zu zeigen. Schau bald wieder hier vorbei für mehr Funktionen.',
  without_topic: 'Ohne Thema',
  all_topics: 'Alle Themen',
  related_topics: 'Verwandte Themen',
  all_statements: 'Alle Aussagen',
  main_statements: 'Hauptaussagen',
  members: 'Mitglieder',
  add_member: 'Mitglied hinzufügen',
  add_member_hint: 'Gib mindestens einen Namen für die Person ein, die du zur Gruppe hinzufügen möchtest. Verwende keine doppelten Namen.',
  remove_member: 'Mitglied entfernen',
  remove_member_hint: 'Bist du sicher, dass du das Mitglied entfernen möchtest?',
  you_are_group_admin: 'Du bist Gruppenadministrator',
  delete_topic: 'Thema löschen',
  delete_topic_alert: 'Bist du sicher, dass du das Thema löschen möchtest?',
  edit_topic_name: 'Beschreibung bearbeiten',
  group_link_copied: 'Gruppenlink kopiert',
  group_link_copied_hint: "Der Gruppenlink wurde in die Zwischenablage kopiert. Teile ihn nur mit deinen Gruppenmitgliedern. Sie benötigen trotzdem ihre Anmeldedaten, um auf die Gruppe zuzugreifen.",
  share_group_link: 'Gruppenlink teilen',
  not_a_group_member_hint: 'Du bist kein Mitglied dieser Gruppe? Frage den Gruppenadministrator, Dich zur Gruppe hinzuzufügen.',
  member_name: 'Mitgliedsname',
  member_credentials_copied: 'Mitgliedsdaten kopiert',
  member_credentials_copied_hint: 'Die Mitgliedsdaten wurden in die Zwischenablage kopiert. Du kannst sie jetzt an das Mitglied weitergeben.',
  member_login_failed: 'Mit den Mitgliedsdaten konnte kein Mitglied gefunden werden.',
  change_member_in: 'Mitglied ändern in',
  add_member_to: 'Mitglied hinzufügen zu',
  change_member: 'Mitglied ändern',
  generated_password_hint: "Leer lassen für automatisch generiertes Passwort",
  create_group: 'Gruppe erstellen',
  group_name: 'Gruppenname',
  new_group: 'Neue Gruppe',

  // Checks and conflicts
  conflicts: 'Konflikte',
  conflicts_info:
    "Konflikte sind Inkonsistenzen in Deiner Weltanschauung. Inkonsistenzen werden durch Kontrollen gefunden. Mögliche Kontrollen siehst Du unten. Diese Kontrollen sind noch nicht implementiert. Klicke auf einen Konflikt in der Liste, um Erklärungen zu sehen. Klicke auf 'Beispiel anzeigen', um zu sehen, wie es für ein Beispiel aussehen würde.",
  publish_checks_coming_soon_info:
    'Es müssen bestimmte Bedingungen erfüllt sein, damit eine Aussage veröffentlicht werden kann. Im Moment braucht die Aussage nur mindestens ein unterstützendes Argument. In Zukunft wird es zusätzlich eine Prüfung nach Duplikaten geben, sodass nur noch komplett neue Aussagen oder Argumente gepostet werden können.',
  checks_failed:
    'Kontrollen teilweise fehlgeschlagen. Passe den Inhalt an, um veröffentlichen zu können.',
  checks_successful: 'Kontrollen erfolgreich.',
  checks_loading: 'Kontrollen teilweise noch nicht abgeschlossen.',
  duplicate_check_info:
    'Es sollten nur neue Aussagen und Argumente veröffentlicht werden, damit keine künstliche Relevanz eines Themas erzeugt werden kann.',
  duplicate_check_successful: 'Keine Duplikate gefunden',
  duplicate_check_failed: 'Duplikat gefunden',
  duplicate_check_loading: 'Suche Duplikate...',
  duplicates_not_checked: 'Duplikate nicht überprüft (Bald verfügbar)',
  supporting_arguments_check_info:
    'Eine Aussage sollte immer mindestens mit einem unterstützenden Argument erklärt werden.',
  supporting_arguments_not_found: 'Keine unterstützdenden Argumente gefunden',
  supporting_arguments_found: 'Unterstützende Argumente gefunden',
  supporting_arguments_loading: 'Suche unterstützende Argumente...',
  supporting_arguments_not_checked: 'Unterstützende Argumente nicht überprüft',
  source_check_info:
    'Quellen sollten vertrauenswürdig sein und wissenschaftliche oder journalistische Standards wahren. Beachte, dass für Aussagen, die eine Meinung sind, Quellen lediglich der Referenz dienen und nichts über den Wahrheitsgehalt der Aussage sagen.',
  source_not_checked: 'Quelle wurde nicht überprüft (Bald verfügbar)',
  source_valid: 'Quelle valide',
  source_invalid: 'Quelle nicht valide', // TODO Difference between source not trustworthy, source fake, source insufficient to proof statement?
  language_check_info:
    'Die Sprache für einen produktiven Diskurs sollte möglichst neutral und sachlich sein. Wähle Begriffe sorgfältig und vermeide Polemik.',
  language_objective: 'Sachliche Ausdrucksweise',
  language_not_checked: 'Ausdrucksweise nicht überprüft (Bald verfügbar)',
  language_unobjective: 'Unsachliche Ausdrucksweise',
  relevant_arguments_check_info:
    'Wenn du einer Aussage aktiv zustimmst oder nicht zustimmst, solltest du jeweils alle Argumente die gegen deine Auffassung zumindest gehört haben.',
  relevant_arguments_resolve_prompt:
    'Schaue dir alle Argumente gegen deine Auffassung zu dieser Aussage an, um den Konflikt aufzulösen',
  relevant_arguments_not_checked:
    'Argumente gegen Deine Position nicht überprüft (Bald verfügbar)',
  relevant_arguments_unseen:
    'Es gibt ungesehene Argumente gegen deine Einstellung',
  relevant_arguments_seen:
    'Alle veröffentlichten Argumente gegen deine Einstellung gesehen', // TODO Einstellung / Anschauung / Auffassung
  resolve_conflict: 'Auflösen',
  resolve_tooltip: 'Geführte Auflösung des Konflikts bald implementiert',

  // authentification
  login: 'Anmelden',
  logout: 'Abmelden',
  register: 'Registrieren',
  register_call_to_action: 'Jetzt registrieren.',
  display_name: 'Anzeigename',
  email: 'E-Mail', // Alternative: "Mailadresse"
  password: 'Passwort',
  already_have_account: 'Ich habe bereits einen Account!',
  forgot_password: 'Passwort vergessen?',
  no_account: 'Noch keinen Account?', // Alternative: "Noch kein Benutzerkonto?"
  enter_email_password: 'Bitte geben Sie Ihre E-Mail und Passwort ein.',
  invalid_email: 'Diese E-Mail-Adresse ist ungültig!',
  wrong_email_password: 'Falsche E-Mail oder Passwort!',
  enter_email: 'Bitte geben Sie Ihre E-Mail ein.',
  reset_link_sent: 'Sofern es einen Account zu Ihrer E-Mail-Adresse gibt, wurde Ihnen ein Link zum Zurücksetzen des Passworts gesendet.',
  login_to_use_this_feature: 'Bitte melde Dich an, um diese Funktion zu nutzen.',

  // Landing Page
  see_test_version: "Jetzt registrieren",
  header_header: "Informiere Dich einfach und logisch über politische Themen",
  header_text: "Sehnst Du Dich nach einem vernünftigen und rationalen Meinungsaustausch? Bilde Deine Meinung auf LucidMind, um alle Argumente zu kennen und immer auf dem neusten Stand zu bleiben.",
  introduction_group_header: "Informiert sein ist jetzt einfach",
  introduction_group_header_soft: "Informiert sein ist jetzt einfacher",
  introduction_group_statements_header: "Teile Deine Meinung logisch",
  introduction_group_statements_text: "Bei LucidMind werden Aussagen geteilt. Aussagen müssen mit Herleitung gepostet werden. Dies sind entweder Quellen oder andere Aussagen.",
  introduction_group_logical_tree_header: "Behalte die Übersicht",
  introduction_group_logical_tree_text: "Alle Aussagen sind in einem großen Netz logisch verbunden. So kannst du Dich schnell und einfach über neue Themen informieren.",
  introduction_group_interaction_header: "Lerne die Welt zu verstehen",
  introduction_group_interaction_header_soft: "Ordne Deine Gedanken",
  introduction_group_interaction_text: "Entscheide, welche Aussagen Du glaubst und welche Herleitungen Du verstehst. Bilde so Deine eigene Weltanschauung.",
  argument_proposal_header: "Bleib auf dem Laufenden",
  argument_proposal_text: "Lehne Dich zurück. Neue Argumente und Argumente gegen Deine Weltanschauung werden Dir in Dein Feed gespült. So lernst Du Deine Argumente zu festigen oder zu verwerfen.",
  pro_contra_argument_header_soft: "Komme der Wahrheit näher",
  pro_contra_argument_header: "Finde die Wahrheit",
  pro_contra_argument_text: "Wäge zwischen Pro und Kontra Argumenten ab, um Deine Meinung zu bilden. Behalte die Übersicht über alle Argumente.",
  logical_network_header: "Bilde Deine Meinung",
  logical_network_text: "Dein eigenes logisches Netzwerk hilft Dir dabei zu wissen, was und warum Du glaubst. Nutze es bei Diskussionen mit Verwandten und Freunden und lerne sachlich zu bleiben.",
  conflictsChecks_header: "Argumentiere schlüssig",
  conflictsChecks_text: "Aussagen werden bestimmten transparenten Kontrollen unterzogen, die Dir helfen sollen, Deine Argumentation möglichst schlüssig aufzubauen.",
  call_to_action_header: "Werde der Bürger, der Du sein solltest.",
  call_to_action_header_soft: "Bleibe informiert mit LucidMind.",
  call_to_action_text: "Melde Dich zur Testversion an.",
  anonymized_opinions_header: "Anonymisierte Meinungen",
  anonymized_opinions_text: "Bewerte Meinungen nicht anhand von denen, die sie geäußert haben, sondern nur anhand ihres Inhalts.",

  // Knowledge Base Approach
  header_knowledge_base: "Deine persönliche, KI-unterstützte logische Wissensdatenbank",
  header_knowledge_base_text: "Ein Ort für alle Quellen, Fakten und Meinungen. Baue eine konsistente Weltansicht auf der Grundlage logischer Schlussfolgerungen auf. Beseitige Inkonsistenzen und teile Dein Wissen, um eine breitere Sicht auf jedes Thema zu erhalten.",
  artificial_intelligence_supported_groud_header: "Informiere Dich mithilfe neuester KI-Methoden",
  knowledge_extraction_header: "Informationsgewinnung",
  knowledge_extraction_text: "Extrahiere Aussagen aus Quellen wie Nachrichten oder wissenschaftlichen Artikeln mit einem Klick. Verschwende keine Zeit auf langwierige Recherche.",
  automatic_reasoning_header: "Automatisches Schlussfolgern",
  automatic_reasoning_text: "Strukturiere Aussagen automatisch logisch, um einen Überblick über unstrukturierte Informationen zu erhalten.",
  argument_generation_header: "Generiere Argumente",
  argument_generation_text: "Verwende Generative Künstliche Intelligenz, um neue Perspektiven zu Themen zu finden.",
  share_knowledge_header: "Geteiltes Wissen",
  share_knowledge_text: "Teile Deine Meinungen mit anderen und integriere das Wissen anderer in Deine Weltanschauung.",
  automatic_critizism_header: "Bleib Konsistent",
  automatic_critizism_text: "Wir erkennen Ungereimtheiten in Deinem Weltbild und helfen Dir, diese aufzulösen.",
  conquer_your_bias_header: "Überwinde Deine Vorurteile",
  conquer_your_bias_text: "Du erhältst automatisch alle relevanten Argumente, um Deine Ansicht zu hinterfragen.",
  source_fact_opinion: "Von der Quelle zum Fakt zur Meinung",

  // Singular Information Source Approach
  header_information_source: "Deine einzige Informationsquelle für eine schnelllebige Welt",
  header_information_source_text: "Ein Platz für alle Quellen, Fakten und Meinungen. Erhalte logisch strukturierte Inhalte aus Nachrichten oder wissenschaftlichen Artikeln und bette diese in Dein Weltbild ein. Konzentriere Dich nur auf die wichtigsten Punkte und Themen, die für Dich relevant sind.",
  clean_feed_header: "Ein Feed für Alles",
  clean_feed_text: "Ein sauberer Feed mit nur grundlegend neuen Aussagen ohne Unordnung oder Spam.",
  one_place_for_all_header: "Ein Platz für alle Quellen, Fakten und Meinungen.",
  time_saving_header: "Zeiteffizient",
  time_saving_text: "Reduziere Lesezeit, indem Du Dich nur auf die wichtigen Teile von Artikeln konzentrierst.",
  better_overview_header: "Strukturiert",
  better_overview_text: "Verschaffe Dir einen Überblick über Themen mit logisch strukturierten Inhalten. Neue Informationen werden automatisch in ein großes logisches Netzwerk eingebettet.",
  deeper_understanding_header: "Erlange ein besseres Verständnis",
  deeper_understanding_text: "Verstehe die Gegenseite, indem Du automatisch Gegenargumente zu deinen Meinugnen erhälst.",
  benefit_unbiased_header: "Unparteiisch",
  benefit_unbiased_text: "Kenne alle Meinungen von allen Seiten, indem du alle unterstützenden und widersprechenden Argumente einer Aussage erhältst.",

  // Benefits
  benefit_fast_header: "Schnell",
  benefit_fast_header_alternative_1: "Reduziere Lesezeit",
  benefit_fast_text: "Reduziere Lesezeit, indem Du Dich nur auf die wichtigen Teile von Artikeln konzentrierst.",
  benefit_shared_header: "Gemeinsam",
  benefit_shared_text: "Tausche Wissen mit anderen aus, um eine breitere und tiefere Sicht auf Themen zu gewinnen. Lass andere deine Meinungen kritisieren, um sie zu konkretisieren.",
  benefit_smart_header: "Smart",
  benefit_smart_header_alternative_1: "KI-unterstützt",
  benefit_smart_text: "Erhalte intelligente Vorschläge für Gegenargumente oder erstelle neue Argumente mit generativer KI.",
  benefit_engaging_header: "Interaktiv",
  benefit_engaging_header_alternative_1: "Gamification",
  benefit_engaging_header_alternative_2: "Fun",
  benefit_engaging_text: "Verdiene Erfolge für das Erlangen einer konsistenten Weltanschauung. Löse Konflikte, um ein höheres Level zu erreichen.",

  // Features
  feature_generated_content_header: "Verkürze Deine Lesezeit",
  feature_generated_content_text: "In der heutigen schnelllebigen Welt ist es wichtiger denn je, Informationen schnell und effizient zu verarbeiten. Mit automatisch generierten Inhalten aus verschiedenen Quellen erlangst du schnell ein Verständnis für alle relevanten Themen.",

  // Call to action
  call_to_action_register_header: "Bleibe informiert.",
  call_to_action_register_text: "Melde Dich jetzt an.",
  call_to_action_elevate_insights_header: "Verbessere dein Verständnis",
  call_to_action_elevate_insights_text: "Navigiere durch komplexe Wissenslandschaften und erhalte Klarheit und Einblick bei jedem Schritt.",
  call_to_action_uncover_knowledge_header: "Enthülle verborgene Einsichten",
  call_to_action_uncover_knowledge_text: "Tauche ein in die Welt des Wissens, wo komplexe Ideen systematisch entfaltet werden.",
  call_to_action_expand_horizons_header: "Erweitere deine Perspektiven",
  call_to_action_expand_horizons_text: "Begib dich auf eine Reise mit anderen Wissenssuchenden, fördere vielfältige Meinungen und frische Standpunkte.",
  call_to_action_register_test_header: "Erlebe LucidMind aus erster Hand",
  call_to_action_register_test_text: "Erkunde Funktionen und erlebe den Einfluss auf deine Erkenntnisse.",
  call_to_action_try_solution_header: "Probiere LucidMind noch heute aus",
  call_to_action_try_solution_text: "Tauche in die Welt von LucidMind ein. Erlebe, wie es dein Verständnis transformiert.",
  call_to_action_get_test_access_header: "Jetzt Zugang zur Testversion erhalten",
  call_to_action_get_test_access_text: "Erlebe die Kraft der Verbesserung des Verständnisses aus erster Hand.",

  headers: {
    debate_superpowers: {
      header: "Entfessle Deine Debattier-Superkräfte",
      text: "Willkommen beim ultimativen Werkzeug für Debattierer, die nach Perfektion streben. Entfessle Deine rhetorischen Fähigkeiten und gewinne jede Debatte mit Leichtigkeit."
    }
  },
  features: {
    argumentNetwork: {
      webOfKnowledge: {
        header: "Das Netz des Wissens",
        text: "Visualisiere Deine Gedanken in einem großen Netzwerk von miteinander verbundenen Aussagen. Sieh den logischen Fluss deiner Argumente auf einen Blick.",
      }
    },
    aiGeneratedArguments: {
      intelligentArguments: {
        header: "Intelligente Argumentation",
        text: "Nutze fortschrittliche KI, um Deine Argumente nahtlos zu erstellen und zu organisieren.",
      }
    },
    consistencyChecker: {
      consistencyAtGlance: {
        header: "Konsistenz auf einen Blick",
        text: "KI unterstützt dabei Deine Weltanschauung auf Konsistenz zu überprüfen, um sicherzustellen, dass Deine Argumente in Einklang stehen. Keine Widersprüche mehr.",
      }
    },
  },

  // Other
  privacy_policy: "Datenschutzerklärung",
  impressum: "Impressum",
  agb: "AGB",

  // Help content
  help: {
    header: 'Hilfe',
    statement: {
      header: "Was ist eine Aussage?",
      text: "Eine Aussage ist ein ganzer Satz, der wahr oder falsch sein kann. Aussagen können als Teil eines Arguments andere Aussagen begründen oder von anderen Aussagen begründet werden.",
    },
    argument: {
      header: "Was ist ein Argument?",
      text: "Ein Argument besteht aus einer begründeten Aussage und einer oder mehreren begründenden Aussagen. Die begründete Aussage wird Schlussfolgerung genannt; die begründenden Aussagen werden Annahmen genannt. Ein Argument ist vollständig, wenn  es nicht möglich ist, dass die Schlussfolgerung falsch ist, obwohl alle Annahmen wahr sind.",
    },
    topic: {
      header: "Was ist ein Thema?",
      text: "Ein Thema ist eine Kategorie für Aussagen und Argumenten. Themen können mit Aussagen verknüpft werden.",
    },
    premise: {
      header: "Was ist eine Annahme?",
      text: "Eine Annahme ist eine Aussage, die als Teil eines Arguments eine andere Aussage begründet. Ein Argument kann eine oder mehrere Annahmen haben.",
    },
    conclusion: {
      header: "Was ist eine Schlussfolgerung?",
      text: "Eine Schlussfolgerung ist eine Aussage, die als Teil eines Arguments von einer oder mehreren anderen Aussagen begründet wird. Ein Argument hat nur eine Schlussfolgerung.",
    },
    personal_area: {
      header: "Was ist der persönliche Bereich?",
      text: "Im persönlichen Bereich kannst du deine eigene Meinung als Aussagen und Argumente strukturieren und hinzufügen.",
    },
    public_area: {
      header: "Was ist der öffentliche Bereich?",
      text: "Im öffentlichen Bereich kannst du deine eigenen Aussagen teilen und Aussagen anderer finden. Zukünftig werden dir auch automatisch Aussagen und Argumente aus dem öffentlichen Bereich vorgeschlagen, um deine Meinung zu hinterfragen.",
    },
    publish: {
      header: "Aussagen und Argumente veröffentlichen",
      text: "Aussagen veröffentlichen heißt, dass diese im öffentlichen Bereich für alle Nutzer sichtbar sind. Du kannst eine Aussage veröffentlichen, indem du mindestens ein unterstützendes Argument hinzufügst.",
    },
    conflict: {
      header: "Was ist ein Konflikt?",
      text: "Konflikte sind Inkonsistenzen in Deiner Weltanschauung. Inkonsistenzen werden durch Kontrollen gefunden. Manche Konflikte müssen aufgelöst werden, um eine Aussage veröffentlichen zu können. Andere Konflikte sind optional und können aufgelöst werden, um eine konsistentere Weltanschauung zu erhalten.",
    },
    source: {
      header: "Was ist eine Quelle?",
      text: "Eine Quelle ist eine Referenz zu einem Zitat in einem Medium, das eine Aussage begründet. Quellen können z.B. Nachrichtenartikel, wissenschaftliche Artikel oder Bücher sein.",
    },
  },

};
