import React, { useEffect, useRef } from 'react';
import { Animated, Platform } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { View } from 'react-native-ui-lib';
import LogoIcon from '../../assets/res/LogoLM50.svg';

export function Splashscreen() {
  const fadeAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(fadeAnim, {
          toValue: 0.8,
          duration: 300,
          useNativeDriver: Platform.OS !== "web" ? true : false,
        }),
        Animated.timing(fadeAnim, {
          toValue: 1,
          duration: 300,
          useNativeDriver: Platform.OS !== "web" ? true : false,
        }),
        Animated.timing(fadeAnim, {
          toValue: 1,
          duration: 300,
          useNativeDriver: Platform.OS !== "web" ? true : false,
        }),
        Animated.timing(fadeAnim, {
          toValue: 0.8,
          duration: 300,
          useNativeDriver: Platform.OS !== "web" ? true : false,
        }),
      ])
    ).start();
  }, [fadeAnim]);

  return (
    <View flex center bg-primaryFG>
      <Animated.View style={{
        opacity: fadeAnim,
      }}>
        <LogoIcon style={{ height: 50, width: 50 }} />
      </Animated.View>
    </View>
  );
}
