
import { convertInfoToPath, convertRouteParamsToInfo, getContainerTypeFromPath, locToUrlString } from "../helperFunctions";
import { ContainerType, ElementPath, FirestoreConstants, PathConstants, navigationProps } from "../typings/types";
import { StatementList } from "./StatementList";
import { StatementThread } from "./StatementThread";

export function StatementView({
  navigation,
  route,
}: navigationProps): JSX.Element {
  const info: ElementPath = convertRouteParamsToInfo(route.params) as ElementPath;

  if (info.id === FirestoreConstants.ROOT_ID || [ContainerType.CATEGORY, ContainerType.ARGUMENT].includes(info.type)) {
    return (
      <StatementList navigation={navigation} route={route} />
    );
  } else {
    return (
      <StatementThread navigation={navigation} route={route} />
    );
  }
}

export function GroupStatementView({
  navigation,
  route,
}: navigationProps): JSX.Element {
  let newRoute = { ...route };
  newRoute.params = { ...newRoute.params, loc: locToUrlString(PathConstants.GROUP) };
  return <StatementView navigation={navigation} route={newRoute} />;
}

export function PublicStatementView({
  navigation,
  route,
}: navigationProps): JSX.Element {
  let newRoute = { ...route };
  newRoute.params = { ...newRoute.params, loc: locToUrlString(PathConstants.PUBLIC) };
  return <StatementView navigation={navigation} route={newRoute} />;
}

export function PersonalStatementView({
  navigation,
  route,
}: navigationProps): JSX.Element {
  let newRoute = { ...route };
  newRoute.params = { ...newRoute.params, loc: locToUrlString(PathConstants.PERSONAL) };
  return <StatementView navigation={navigation} route={newRoute} />;
}