import { FC, useState } from "react";
import { ConflictSeverity, ConflictType, ContainerType } from "../typings/types";
import { TouchableHighlight, Pressable } from "react-native";
import { Button, TouchableOpacity, Text, Colors, View } from "react-native-ui-lib";
import { styles } from "../styles";
import { ConflictModal } from "./Dialogs";
import LinearGradient from "react-native-linear-gradient";

export type ConflictBadgeProps = {
    containerType: ContainerType,
    conflicts: any[],
    showConflictModalExternal?: boolean,
    setShowConflictModalExternal?: (show: boolean) => void,
}

export const ConflictBadge: FC<ConflictBadgeProps> = (
    {
        containerType,
        conflicts,
        showConflictModalExternal,
        setShowConflictModalExternal
    }
) => {

    const [showConflictModal, setShowConflictModal] = useState(false);

    let worstSeverity = conflicts.filter((conflict: any) => conflict.severity === ConflictSeverity.CRITICAL).length > 0 ?
        ConflictSeverity.CRITICAL
        : undefined;
    worstSeverity = worstSeverity === undefined ?
        conflicts.filter((conflict: any) => conflict.severity === ConflictSeverity.MAJOR).length > 0 ?
            ConflictSeverity.MAJOR
            : undefined
        : worstSeverity;
    worstSeverity = worstSeverity === undefined ?
        conflicts.filter((conflict: any) => conflict.severity === ConflictSeverity.MINOR).length > 0 ?
            ConflictSeverity.MINOR
            : undefined
        : worstSeverity;
    worstSeverity = worstSeverity === undefined ? ConflictSeverity.NONE : worstSeverity;

    let badgeStyle: any;
    switch (worstSeverity) {
        case ConflictSeverity.CRITICAL:
            badgeStyle = styles.conflictBadgeCritical;
            break;
        case ConflictSeverity.MAJOR:
            badgeStyle = styles.conflictBadgeMajor;
            break;
        case ConflictSeverity.MINOR:
            badgeStyle = styles.conflictBadgeMinor;
            break;
        default:
            badgeStyle = styles.conflictBadgeNoConflict;
            break;
    }

    const handleConflictBadgeClick = (event: any) => {
        if (setShowConflictModalExternal) {
            setShowConflictModalExternal(true);
        } else {
            setShowConflictModal(true);
        }
    }

    const handleCloseConflictModal = (event: any) => {
        if (setShowConflictModalExternal) {
            setShowConflictModalExternal(false);
        } else {
            setShowConflictModal(false);
        }
    }

    return (
        <>
            <TouchableHighlight
                onPress={handleConflictBadgeClick}
                style={{ borderRadius: 10, marginTop: 0 }}
            >
                <View style={[styles.conflictBadge, badgeStyle]} >
                </View>
            </TouchableHighlight>
            <ConflictModal
                show={showConflictModalExternal !== undefined ? false : showConflictModal}
                handleClose={handleCloseConflictModal}
                containerType={containerType}
                conflicts={conflicts}
            />
        </>

    )

}

export const TopicBadge: FC<{ topic: string, onPress: Function }> = ({ topic, onPress }) => {
    return (
        <Pressable
            style={[
                {
                    elevation: 3,
                },
            ]}
            onPress={() => onPress()}
        >
            <LinearGradient
                start={{ x: 0, y: 0 }}
                end={{ x: 1, y: 0 }}
                colors={[Colors.backgroundAccent, Colors.backgroundAccent2]}
                style={[styles.borderPrimary, { width: 'auto' }]}
            >
                <View padding-2 style={[
                    {
                        elevation: 3,
                    },
                    styles.borderPrimary,
                ]}>
                    <View row flexS paddingH-10 centerV>
                        <View flex row centerV>
                            <Text h5 marginV-5 style={styles.textAccentSecondary}>
                                {topic}
                            </Text>
                        </View>
                    </View>
                </View>
            </LinearGradient>
        </Pressable>
    )
}